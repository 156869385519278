import { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { GameContext } from "views/Game/GameContext";
import { useTranslation } from "react-i18next";

// Import reducers
import { completedGameReducer } from "store/gameSlice";

// Import styles, media and components
import Wrapper from "components/Wrapper/Wrapper";
import VR from "views/Game/VR/VR";
import SwipeUnlock from "components/SwipeUnlock/SwipeUnlock";

import imgARbg from "assets/images/AR1.png";

function Game6({ gamesData }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { showScreens, setShowScreens } = useContext(GameContext);

  const isLockedClickHandler = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      setShowScreens({
        ...showScreens,
        gameLocked: false,
      });

      window.open(t("G6VR1Android"), "_blank");
    } // iOS detection from: http://stackoverflow.com/a/9039885/177710
    else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setShowScreens({
        ...showScreens,
        showVR: true,
      });
    } else {
      setShowScreens({
        ...showScreens,
        gameLocked: false,
        showVR: true,
      });
    }

    setTimeout(() => {
      setShowScreens({ ...showScreens, showSuccess: true });
      dispatch(completedGameReducer(gamesData.id));
    }, 5000);
  };

  return (
    <>
      {showScreens.showVR ? (
        <VR img={imgARbg} link={t("G6VR1Iphone")} />
      ) : (
        <Wrapper className="game6 minh-100 d-flex align-content-between flex-wrap ">
          <p className="lead">{t("swipetostart")}</p>

          <div
            className={`w-100 ${
              showScreens.gameLocked ? "d-block" : "d-none"
            }`}>
            <SwipeUnlock getter={showScreens} setter={isLockedClickHandler} />
          </div>
        </Wrapper>
      )}
    </>
  );
}

export default Game6;
