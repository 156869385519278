import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { staggerItemsFade, itemFade } from "utils/animations";

// Import styles, media and components
import Wrapper from "components/Wrapper/Wrapper";
import Button from "components/Button/Button";

import ImgLogo from "assets/images/logo.png";
import ImgPin from "assets/images/icons/pins.png";

function PreGameIntro3({ gamesData, step, setStep }) {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Wrapper className="pregame px-0 bg-gradient-blue-dark">
      <motion.div variants={staggerItemsFade} initial="hidden" animate="show">
        <img src={ImgLogo} className="mb-4 w-35" alt="" />
        <motion.div variants={itemFade}>
          <p className="lead px-4">{t("G0Intro3H1")}</p>

          <p
            className="px-3"
            dangerouslySetInnerHTML={{ __html: t("G0Intro3P1") }}
          />
          <div
            className="text-white px-3 mt-4"
            dangerouslySetInnerHTML={{ __html: t("G0Intro3P2") }}
          />
        </motion.div>

        <motion.div variants={itemFade}>
          {location.pathname === "/kako-deluje-ar" ? (
            <Button
              onClick={() => navigate(-1)}
              label={t("next")}
              className="btn btn-primary mt-2"
            />
          ) : (
            <Button
              onClick={() => setStep(3)}
              label={t("next")}
              className="btn btn-primary mt-2"
            />
          )}
        </motion.div>
      </motion.div>
    </Wrapper>
  );
}

export default PreGameIntro3;
