import { useContext } from "react";
import { GameContext } from "../GameContext";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { staggerItemsFade, itemFade } from "utils/animations";

// Import styles, media and components
import Wrapper from "components/Wrapper/Wrapper";
import Button from "components/Button/Button";
import imgSilhuetaM from "assets/images/silhueta_m.svg";
import imgSilhuetaZ from "assets/images/silhueta_z.svg";

function GameBrief({ gamesData }) {
  const { t } = useTranslation();

  const { showScreens, setShowScreens } = useContext(GameContext);

  return (
    <Wrapper className="bg-white game-brief h-100">
      <motion.div variants={staggerItemsFade} initial="hidden" animate="show">
        <motion.div variants={itemFade}>
          {gamesData.id === "game1" || gamesData.id === "game3" ? (
            <img src={imgSilhuetaZ} className="w-50" />
          ) : (
            <img src={imgSilhuetaM} className="w-50" />
          )}
        </motion.div>
        <motion.div className="mt-1" variants={itemFade}>
          <div dangerouslySetInnerHTML={{ __html: gamesData.briefIntro }}></div>
        </motion.div>

        <Button
          onClick={() =>
            setShowScreens({
              ...showScreens,
              showBrief: false,
              showBrief2: true,
            })
          }
          label={t("startgame")}
          className="btn btn-primary mt-3"
        />
      </motion.div>
    </Wrapper>
  );
}

export default GameBrief;
