import { useContext } from "react";
import { GameContext } from "../GameContext";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { staggerItemsFade, itemFade } from "utils/animations";

// Import styles, media and components
import Wrapper from "components/Wrapper/Wrapper";
import Button from "components/Button/Button";

function GameOver({ gamesData }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { showScreens, setShowScreens } = useContext(GameContext);

  return (
    <Wrapper className="game-over">
      <img
        src={"https://via.placeholder.com/300x100/D9D9D9/000000.png?text=IMG"}
        className="my-3 w-100"
        alt=""
      />
      <div dangerouslySetInnerHTML={{ __html: gamesData.gameoverTxt }}></div>

      <Button
        onClick={() => setShowScreens({ ...showScreens, showGameOver: false })}
        label={t("playagain")}
        className="btn btn-sm btn-primary"
      />
    </Wrapper>
  );
}

export default GameOver;
