import { useContext } from "react";
import { GameContext } from "../GameContext";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { staggerItemsFade, itemFade } from "utils/animations";

// Import styles, media and components
import Wrapper from "components/Wrapper/Wrapper";
import Button from "components/Button/Button";
import imgSilhuetaM from "assets/images/silhueta_m.svg";
import imgSilhuetaZ from "assets/images/silhueta_z.svg";

function GameBrief({ gamesData }) {
  const { t } = useTranslation();

  const { showScreens, setShowScreens } = useContext(GameContext);

  return (
    <Wrapper className="bg-white game-brief h-100 bg-gradient-blue-dark">
      <motion.div variants={staggerItemsFade} initial="hidden" animate="show">
        <motion.div variants={itemFade}>
          <div className="text-start mt-4">
            <div className="text-center">
              <p className="heading display-8">{t("mission")}</p>
            </div>

            <div
              className="text-white text-center"
              dangerouslySetInnerHTML={{
                __html: gamesData.briefMission,
              }}></div>
          </div>
        </motion.div>
        <Button
          onClick={() => setShowScreens({ ...showScreens, showBrief2: false })}
          label={t("Start")}
          className="btn btn-primary mt-3"
        />
      </motion.div>
    </Wrapper>
  );
}

export default GameBrief;
