import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { resetGamesReducer } from "store/gameSlice";
import { staggerItemsFade, itemFade, fadeInOut } from "utils/animations";

// Import styles, media and components
import Wrapper from "components/Wrapper/Wrapper";
import Button from "components/Button/Button";
import ImgLogo from "assets/images/logo.png";
import imgBack from "assets/images/icons/back.svg";
import imgLogo from "assets/images/logo_escapebox.svg";

import imgCharacter from "assets/images/icons/character.svg";

const logoAnimation = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      delay: 0.5,
      duration: 2,
    },
  },
  exit: {
    opacity: 0,
  },
};

function Start() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const goToLang = () => {
    //dispatch(resetGamesReducer());

    setTimeout(() => {
      if (JSON.parse(localStorage.getItem("token"))) {
        navigate("../map", { replace: true });
      } else {
        navigate("../logo", { replace: true });
      }
    }, 2000);
  };

  return (
    <Wrapper className="start minh-100 p-0 d-flex align-items-center justify-content-center text-center">
      <motion.img
        variants={logoAnimation}
        initial="hidden"
        animate="show"
        exit="exit"
        onAnimationComplete={() => goToLang()}
        src={imgLogo}
        className="w-75"
        alt=""
      />
    </Wrapper>
  );
}

export default Start;
