import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { motion } from "framer-motion";
import { staggerItemsFade, itemFade } from "utils/animations";

import { selectedLanguageReducer } from "store/gameSlice";
// Import styles, media and components
import Wrapper from "components/Wrapper/Wrapper";
import Button from "components/Button/Button";
import ImgLogo from "assets/images/logo.png";
import ImgLangSl from "assets/images/lang-picker-sl.svg";
import ImgLangDe from "assets/images/lang-picker-de.svg";
import ImgLangEn from "assets/images/lang-picker-en.svg";
import imgBack from "assets/images/icons/back.svg";

function LangPicker() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  function changeLanguage(e) {
    i18n.changeLanguage(e.target.id);
    dispatch(selectedLanguageReducer(e.target.id));
    navigate("/lp", { replace: true });
  }

  return (
    <Wrapper className="lang-picker minh-100 text-center">
      <motion.div variants={staggerItemsFade} initial="hidden" animate="show">
        <img src={ImgLogo} className="mb-5 w-35" alt="" />

        <motion.img
          onClick={changeLanguage}
          id="sl"
          variants={itemFade}
          src={ImgLangSl}
          className="mb-3 d-block mx-auto"
          alt=""
        />
        <motion.img
          onClick={changeLanguage}
          id="de"
          variants={itemFade}
          src={ImgLangDe}
          className="mb-3 d-block mx-auto"
          alt=""
        />
        <motion.img
          id="en"
          onClick={changeLanguage}
          variants={itemFade}
          src={ImgLangEn}
          className="mb-3 d-block mx-auto"
          alt=""
        />
      </motion.div>
    </Wrapper>
  );
}

export default LangPicker;
