import { useState, useContext } from "react";

import { GameContext } from "views/Game/GameContext";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { staggerItemsFade, itemFade } from "utils/animations";

// Import styles, media and components
import Wrapper from "components/Wrapper/Wrapper";
import Button from "components/Button/Button";
import SelfieIntro from "views/Selfie/SelfieIntro/SelfieIntro";
import SelfieTakePhoto from "views/Selfie/SelfieTakePhoto/SelfieTakePhoto";
import SelfieShowPhoto from "views/Selfie/SelfieShowPhoto/SelfieShowPhoto";
import SelfieSharePhoto from "views/Selfie/SelfieSharePhoto/SelfieSharePhoto";

function Selfie({ gamesData }) {
  const [step, setStep] = useState(1);
  const [photo, setPhoto] = useState("");
  const [watermarkPhoto, setWatermarkPhoto] = useState("");

  const navigate = useNavigate();
  const { t } = useTranslation();

  const { showScreens, setShowScreens } = useContext(GameContext);

  return (
    <>
      {step === 0 && <SelfieIntro step={step} setStep={setStep} />}
      {step === 1 && (
        <SelfieTakePhoto step={step} setStep={setStep} setPhoto={setPhoto} />
      )}
      {step === 2 && (
        <SelfieShowPhoto
          step={step}
          setStep={setStep}
          photo={photo}
          watermarkPhoto={watermarkPhoto}
          setWatermarkPhoto={setWatermarkPhoto}
        />
      )}
      {step === 3 && (
        <SelfieSharePhoto
          watermarkPhoto={watermarkPhoto}
          setWatermarkPhoto={setWatermarkPhoto}
        />
      )}
    </>
  );
}

export default Selfie;
