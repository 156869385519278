import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

// Import styles, media and components
import Offcanvas from "react-bootstrap/Offcanvas";

import ImgLogo from "assets/images/logo.png";
import ImgMenu from "assets/images/icons/menu.svg";
import ImgQmark from "assets/images/icons/qmark.svg";
import ImgDummy from "assets/images/statusbar-img-dummy.png";
import imgBulb from "assets/images/icons/icon_bulb.svg";
import imgList from "assets/images/icons/icon_list.svg";

function StatusBar() {
  const [show, setShow] = useState(false);

  const navigate = useNavigate();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { t } = useTranslation();
  const completedGame = useSelector((state) => state.game.completedGame);

  const gs = completedGame[0].completed;
  const g1 = completedGame[1].completed;
  const g2 = completedGame[2].completed;
  const g3 = completedGame[3].completed;
  const g4 = completedGame[4].completed;
  const g5 = completedGame[5].completed;
  const g6 = completedGame[6].completed;

  return (
    <div className="statusbar d-flex align-items-center justify-content-between">
      <div className="logo">
        <img alt="" src={ImgLogo} />
      </div>
      <div className="status-pins d-flex align-items-center">
        <div
          label="G1"
          className={`status-pin d-flex align-items-center justify-content-center pin-1 ${
            g1 ? "completed" : gs ? "active" : "incompleted"
          }`}>
          {" "}
          <img
            alt=""
            className="found"
            src="https://planicar.escapebox.si/public/img/badge-gledalka-char.png"
          />
          <img alt="" className="notfound" src={ImgQmark} />
        </div>

        <div
          label="G2"
          className={`status-pin d-flex align-items-center justify-content-center pin-2 ${
            g2 ? "completed" : gs && g1 ? "active" : "incompleted"
          }`}>
          <img
            alt=""
            className="found"
            src="https://planicar.escapebox.si/public/img/badge-prostovoljec-char.png"
          />
          <img alt="" className="notfound" src={ImgQmark} />
        </div>

        <div
          label="G3"
          className={`status-pin d-flex align-items-center justify-content-center pin-3 ${
            g3 ? "completed" : gs && g1 && g2 ? "active" : "incompleted"
          }`}>
          <img
            alt=""
            className="found"
            src="https://planicar.escapebox.si/public/img/badge-novinarka-char.png"
          />
          <img alt="" className="notfound" src={ImgQmark} />
        </div>

        <div
          label="G4"
          className={`status-pin d-flex align-items-center justify-content-center pin-4 ${
            g4 ? "completed" : gs && g1 && g2 && g3 ? "active" : "incompleted"
          }`}>
          <img
            alt=""
            className="found"
            src="https://planicar.escapebox.si/public/img/badge-inzenir-char.png?v4"
          />
          <img alt="" className="notfound" src={ImgQmark} />
        </div>

        <div
          label="G5"
          className={`status-pin d-flex align-items-center justify-content-center pin-5 ${
            g5
              ? "completed"
              : gs && g1 && g2 && g3 && g4
              ? "active"
              : "incompleted"
          }`}>
          <img
            alt=""
            className="found"
            src="https://planicar.escapebox.si/public/img/badge-trener-char.png?v4"
          />
          <img alt="" className="notfound" src={ImgQmark} />
        </div>

        <div
          label="G6"
          className={`status-pin d-flex align-items-center justify-content-center pin-6 ${
            g6
              ? "completed"
              : gs && g1 && g2 && g3 && g4 && g5
              ? "active"
              : "incompleted"
          }`}>
          <img
            alt=""
            className="found"
            src="https://planicar.escapebox.si/public/img/badge-skakalec-char.png?v4"
          />
          <img alt="" className="notfound" src={ImgQmark} />
        </div>
      </div>

      <img alt="" onClick={handleShow} className="hamburger" src={ImgMenu} />

      <Offcanvas
        show={show}
        placement="end"
        id="offcanvas"
        onHide={handleClose}>
        <div className="bg-gradient-blue-light pb-5">
          <div class="offcanvas-header">
            <button
              type="button"
              class="btn-close"
              onClick={() => setShow(false)}
              data-bs-dismiss="offcanvas"
              aria-label="Close"></button>
          </div>
          <img className="logo" src={ImgLogo} />
          <div className="content">
            <div className="d-flex align-items-center mb-3">
              <div>
                <div className="circle circle__yellow shadow-dark bg-gradient-yellow d-flex align-items-center justify-content-center">
                  <img src={imgBulb} />
                </div>
              </div>

              <p
                className="lead ps-4 text-white mb-0"
                dangerouslySetInnerHTML={{ __html: t("menutxt1") }}></p>
            </div>

            <div className="d-flex align-items-center mb-3">
              <div>
                <div className="circle circle__yellow shadow-dark bg-gradient-yellow d-flex align-items-center justify-content-center">
                  <img src={imgList} />
                </div>
              </div>

              <p className="lead ps-4 text-white mb-0">
                <Link className="text-white" to={`/pravila-igre`}>
                  {t("gamesrules")}
                </Link>
              </p>
            </div>

            <div className="d-flex align-items-center mb-4">
              <div>
                <div className="circle circle__yellow shadow-dark bg-gradient-yellow d-flex align-items-center justify-content-center">
                  <img src={imgList} />
                </div>
              </div>

              <p className="lead ps-4 text-white mb-0">
                <Link className="text-white" to={`/kako-deluje-ar`}>
                  Kako deluje tehnologija AR?
                </Link>
              </p>
            </div>

            <div className="d-flex align-items-center mb-4">
              <div>
                <div className="circle circle__yellow shadow-dark bg-gradient-yellow d-flex align-items-center justify-content-center">
                  <img src={imgList} />
                </div>
              </div>

              <p className="lead ps-4 text-white mb-0">
                <Link className="text-white" to={`/sistemska-obvestila`}>
                  Sistemska obvestila
                </Link>
              </p>
            </div>
          </div>
        </div>
        <Offcanvas.Body className="text-center mt-2">
          <h1>{t("yourheroes")}</h1>

          <div className="badges mt-3">
            <div
              className={`badge ${
                g1 ? "completed" : gs ? "active" : "incompleted"
              }`}>
              <img
                className="game1"
                src="https://planicar.escapebox.si/public/img/badge-gledalka.png?v4"
              />
            </div>
            <div
              className={`badge ${
                g2 ? "completed" : gs && g1 ? "active" : "incompleted"
              }`}>
              <img
                className="game2"
                src="https://planicar.escapebox.si/public/img/badge-prostovoljec.png?v4"
              />
            </div>
            <div
              className={`badge ${
                g3 ? "completed" : gs && g1 && g2 ? "active" : "incompleted"
              }`}>
              <img
                className="game3"
                src="https://planicar.escapebox.si/public/img/badge-novinarka.png?v4"
              />
            </div>
            <div
              className={`badge ${
                g4
                  ? "completed"
                  : gs && g1 && g2 && g3
                  ? "active"
                  : "incompleted"
              }`}>
              <img
                className="game4"
                src="https://planicar.escapebox.si/public/img/badge-inzenir.png?v4"
              />
            </div>
            <div
              className={`badge ${
                g5
                  ? "completed"
                  : gs && g1 && g2 && g3 && g4 && g4
                  ? "active"
                  : "incompleted"
              }`}>
              <img
                className="game5"
                src="https://planicar.escapebox.si/public/img/badge-trener.png?v4"
              />
            </div>
            <div
              className={`badge ${
                g6
                  ? "completed"
                  : gs && g1 && g2 && g3 && g4 && g5
                  ? "active"
                  : "incompleted"
              }`}>
              <img
                className="game6"
                src="https://planicar.escapebox.si/public/img/badge-skakalec.png?v4"
              />
            </div>
          </div>

          <div className="status-pins d-flex align-items-center">
            <div
              label="G0"
              className={`status-pin ${gs ? "completed" : "active"}`}
            />

            <div
              label="G1"
              className={`status-pin ${
                g1 ? "completed" : gs ? "active" : "incompleted"
              }`}
            />

            <div
              label="G2"
              className={`status-pin ${
                g2 ? "completed" : gs && g1 ? "active" : "incompleted"
              }`}
            />

            <div
              label="G3"
              className={`status-pin ${
                g3 ? "completed" : gs && g1 && g2 ? "active" : "incompleted"
              }`}
            />

            <div
              label="G4"
              className={`status-pin ${
                g4
                  ? "completed"
                  : gs && g1 && g2 && g3
                  ? "active"
                  : "incompleted"
              }`}
            />

            <div
              label="G5"
              className={`status-pin ${
                g5
                  ? "completed"
                  : gs && g1 && g2 && g3 && g4
                  ? "active"
                  : "incompleted"
              }`}
            />

            <div
              label="G6"
              className={`status-pin ${
                g6
                  ? "completed"
                  : gs && g1 && g2 && g3 && g4 && g5
                  ? "active"
                  : "incompleted"
              }`}
            />
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default StatusBar;
