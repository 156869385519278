import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { GameContext } from "../GameContext";
import axios from "axios";
import { staggerItemsFade, itemFade } from "utils/animations";

// Import styles, media and components
import Wrapper from "components/Wrapper/Wrapper";
import Button from "components/Button/Button";

import imgBack from "assets/images/icons/back.svg";
import imgInfo from "assets/images/icons/info.svg";
import imgUsp01 from "assets/images/icons/pinopened-usp01.svg";
import imgUsp02 from "assets/images/icons/pinopened-usp02.svg";
import imgUsp03 from "assets/images/icons/pinopened-usp03.svg";

function PinOpened({ gamesData }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { showScreens, setShowScreens } = useContext(GameContext);

  const gameId = gamesData.id.replace(/\D/g, "");

  useEffect(() => {
    // Start game - ignore game0
    if (gameId.length > 0) {
      axios
        .put(
          "https://planicar.escapebox.si/api/public/user/games",
          {
            games: {
              [gameId]: false,
            },
          },
          {
            headers: {
              Authorization:
                "Bearer " + JSON.parse(localStorage.getItem("token")),
            },
          }
        )
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, []);

  return (
    <Wrapper className="pin-opened bg-white minh-100 px-0">
      <motion.div variants={staggerItemsFade} initial="hidden" animate="show">
        <div className="bg-gradient-blue rounded-3 m-2 p-3 ">
          <div className="row">
            <motion.div variants={itemFade} className="col-4">
              <div className="circle circle__yellow shadow-dark bg-gradient-yellow d-flex align-items-center justify-content-center mb-3">
                <img src={imgUsp01} />
              </div>
              <p className="small text-uppercase text-white fw-bold mb-1">
                {t("PinOpenedUSP1")}
              </p>
              <p className="small mb-0 text-yellow fw-bold">
                {gamesData.pinopenedLocation}
              </p>
            </motion.div>
            <motion.div variants={itemFade} className="col-4">
              <div className="circle circle__yellow shadow-dark bg-gradient-yellow d-flex align-items-center justify-content-center mb-3">
                <img src={imgUsp02} />
              </div>
              <p className="small text-uppercase text-white fw-bold mb-1">
                {t("PinOpenedUSP2")}
              </p>
              <p className="small mb-0 text-yellow fw-bold">
                {gamesData.pinopenedDistance}
              </p>
            </motion.div>
            <motion.div variants={itemFade} className="col-4">
              <div className="circle circle__yellow shadow-dark bg-gradient-yellow d-flex align-items-center justify-content-center mb-3">
                <img src={imgUsp03} />
              </div>
              <p className="small text-uppercase text-white fw-bold mb-1">
                {t("PinOpenedUSP3")}
              </p>
              <p className="small mb-0 text-yellow fw-bold">
                {gamesData.pinopenedDifficulty}
              </p>
            </motion.div>
          </div>
        </div>

        <motion.div variants={itemFade} className="p-3 pt-0">
          <p className="mt-3">{gamesData.pinopenedInfo}</p>
          <img
            src={`https://planicar.escapebox.si/public/img/pinphotos/${gamesData.briefUrl}?v5`}
            className="my-3 w-100"
            alt=""
          />

          <Button
            onClick={() =>
              setShowScreens({ ...showScreens, showPinOpened: false })
            }
            label={t("lets")}
            className="btn btn-primary mt-4"
          />
        </motion.div>
      </motion.div>

      <img className="button-back" onClick={() => navigate(-1)} src={imgBack} />
    </Wrapper>
  );
}

export default PinOpened;
