import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { staggerItemsFade, itemFade } from "utils/animations";

// Import styles, media and components
import Wrapper from "components/Wrapper/Wrapper";
import Button from "components/Button/Button";

import { ReactComponent as ImgStarLeft } from "assets/images/stars_left.svg";
import { ReactComponent as ImgStarRight } from "assets/images/stars_right.svg";
import imgAllHeroes from "assets/images/allheroes.png";

function Heroes1({ step, setStep }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Wrapper className="heroes pt-0">
      <div className="headline-star-container bg-gradient-blue-light rounded-4 pt-5 pb-4 mt-3 mb-1">
        <h1 className="lead heading-star text-white text-uppercase heading-star__big">
          <ImgStarLeft className="star star-left" />
          Bravo
          <ImgStarRight className="star star-right" />
        </h1>
      </div>

      <img className="w-100" src={imgAllHeroes} />

      <p dangerouslySetInnerHTML={{ __html: t("HeroesP") }}></p>

      <Button
        onClick={() => navigate("../selfie", { replace: true })}
        label={t("foto")}
        className="btn btn-primary"
      />
    </Wrapper>
  );
}

export default Heroes1;
