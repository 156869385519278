import { useState, useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { GameContext } from "views/Game/GameContext";
import { useTranslation } from "react-i18next";

// Import styles, media and components

import Wrapper from "components/Wrapper/Wrapper";
import Button from "components/Button/Button";

import ImgBloudek from "assets/images/bloudek.jpg";

function InfoHearing({ gamesData, setOpenHearing }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { showScreens, setShowScreens } = useContext(GameContext);

  return (
    <Wrapper className={`game4 minh-100 text-center`}>
      <div className="w-100 mb-3">
        <img src={ImgBloudek} />
      </div>

      <div
        className="text-start"
        dangerouslySetInnerHTML={{ __html: t("infoHearing") }}
      />

      <Button
        onClick={() => setOpenHearing(false)}
        label={t("back")}
        className="btn btn-white mt-3"
      />
    </Wrapper>
  );
}

export default InfoHearing;
