const initialState = {
  showPinOpened: true,
  showGamePassword: true,
  showVR: false,
  showPreGameIntros: true,
  showIntro: true,
  showBrief: true,
  showBrief2: true,
  showSuccess: false,
  showBadge: false,
  showGameOver: false,
  score: [],
  gameLocked: true,
};

export default initialState;
