import { useState, useContext } from "react";

import { GameContext } from "views/Game/GameContext";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { staggerItemsFade, itemFade } from "utils/animations";

// Import styles, media and components
import Wrapper from "components/Wrapper/Wrapper";
import Button from "components/Button/Button";

import Heroes1 from "./Heroes1/Heroes1";
import Heroes2 from "./Heroes2/Heroes2";
import Heroes3 from "./Heroes3/Heroes3";

function Heroes({ gamesData }) {
  const [step, setStep] = useState(0);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const { showScreens, setShowScreens } = useContext(GameContext);

  return (
    <>
      {step === 0 && <Heroes1 step={step} setStep={setStep} />}
      {step === 1 && <Heroes2 step={step} setStep={setStep} />}
      {step === 2 && <Heroes3 step={step} setStep={setStep} />}
    </>
  );
}

export default Heroes;
