import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { GameContext } from "views/Game/GameContext";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { fadeInOut, blurInOut } from "utils/animations";

// Import reducers
import { completedGameReducer } from "store/gameSlice";

// Import styles, media and components

import VR from "views/Game/VR/VR";
import SwipeUnlock from "components/SwipeUnlock/SwipeUnlock";
import Wrapper from "components/Wrapper/Wrapper";
import Button from "components/Button/Button";

import imgARbg from "assets/images/AR1.png";
import ImgGifSkakalec from "assets/images/skakalec.gif";

function Quiz({ gamesData, quizData, quizStates, setQuizStates }) {
  const [isFinished, setIsFinished] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { showScreens, setShowScreens } = useContext(GameContext);

  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  const isLockedClickHandler = () => {
    if (quizData.step[quizStates.currentQuestion].hasVR) {
      if (/android/i.test(userAgent)) {
        setQuizStates((quizStates) => ({
          ...quizStates,
          gameLocked: false,
        }));

        window.open(quizData.step[quizStates.currentQuestion].VRAndroid);
      } // iOS detection from: http://stackoverflow.com/a/9039885/177710
      else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        setQuizStates((quizStates) => ({
          ...quizStates,
          showVR: true,
          gameLocked: false,
        }));
      } else {
        setQuizStates((quizStates) => ({
          ...quizStates,
          showVR: true,
          gameLocked: false,
        }));
      }
    }
  };

  const handleAnswerOptionClick = (e, isCorrect) => {
    let id = e.target.id;
    let clickedAnswerButton = document.getElementById(id);
    let allAnswersButtons = document.querySelectorAll(".answer-section button");
    let nextQuestion = quizStates.currentQuestion + 1;

    let correctAnswerIndex = quizData.step[
      quizStates.currentQuestion
    ].answerOptions.findIndex((item) => item.isCorrect === true);
    let answerCorrectBtn = document.getElementById(
      `answer_${correctAnswerIndex}`
    );

    // Disable pressing a button just after user clicks it.

    setQuizStates((quizStates) => ({ ...quizStates, disableAnswer: true }));

    if (isCorrect) {
      clickedAnswerButton.classList.add("correct");

      setQuizStates((quizStates) => ({
        ...quizStates,
        score: quizStates.score.concat(true),
      }));
    } else {
      setQuizStates((quizStates) => ({
        ...quizStates,
        score: quizStates.score.concat(false),
      }));
      clickedAnswerButton.classList.add("wrong");
      answerCorrectBtn.classList.add("correct");
    }

    setTimeout(() => {
      allAnswersButtons.forEach(function (element) {
        element.classList.remove("correct");
        element.classList.remove("wrong");
      });

      // Show win or fail screen if winTxt has content
      if (quizData.step[quizStates.currentQuestion].winTxt !== null) {
        setQuizStates((quizStates) => ({ ...quizStates, showWinFail: true }));
      }

      if (nextQuestion < quizData.step.length) {
        setQuizStates((quizStates) => ({
          ...quizStates,
          currentQuestion: nextQuestion,
          disableAnswer: false,
          gameLocked: quizData.step[quizStates.currentQuestion].hasVR
            ? true
            : false,
        }));
      } else if (nextQuestion === quizData.step.length) {
        setIsFinished(true);
        // Moved this into useEffect
        /*         if (getOccurrence(quizStates.score, true) >= 0) {
          setShowScreens({
            ...showScreens,
            showSuccess: true,
            score: quizStates.score,
          });

          dispatch(completedGameReducer(gamesData.id));
        } else {
          setShowScreens({ ...showScreens, showGameOver: true });
        } */
      }

      /*       if (quizData.step[quizStates.currentQuestion].hasIntro) {
        setQuizStates((quizStates) => ({ ...quizStates, showIntro: true }));
        setTimeout(() => {
          setQuizStates((quizStates) => ({ ...quizStates, showIntro: false }));
        }, 2000);
      } */
    }, 1000);
  };

  // For countdown timer
  const handleAnswerNoTimeLeft = () => {
    let nextQuestion = quizStates.currentQuestion + 1;
    // Disable pressing a button just after user clicks it.

    // Show win or fail screen if winTxt has content
    if (quizData.step[quizStates.currentQuestion].winTxt !== null) {
      setQuizStates((quizStates) => ({ ...quizStates, showWinFail: true }));
    }

    if (nextQuestion < quizData.step.length) {
      setQuizStates((quizStates) => ({
        ...quizStates,
        score: quizStates.score.concat(false),
        currentQuestion: nextQuestion,
        disableAnswer: false,
        gameLocked: quizData.step[quizStates.currentQuestion].hasVR
          ? true
          : false,
      }));
    } else if (nextQuestion === quizData.step.length) {
      setIsFinished(true);
    }
  };

  // Only runs when quiz question has hasIntro enabled
  useEffect(() => {
    if (
      quizData.step[quizStates.currentQuestion].hasIntro &&
      !quizStates.showWinFail
    ) {
      setQuizStates((quizStates) => ({ ...quizStates, showIntro: true }));
      setTimeout(() => {
        setQuizStates((quizStates) => ({ ...quizStates, showIntro: false }));
      }, 5500);
    }
  }, [quizStates.currentQuestion]);

  useEffect(() => {
    if (isFinished) {
      setShowScreens({
        ...showScreens,
        showSuccess: true,
        score: quizStates.score,
      });

      dispatch(completedGameReducer(gamesData.id));
    }
  }, [isFinished]);

  return (
    <>
      {quizStates.showIntro && (
        <div className="intro d-flex align-items-center justify-content-center">
          <img src={ImgGifSkakalec + "?v" + Math.random()} />
        </div>
      )}
      {quizStates.showVR ? (
        <VR
          img={imgARbg}
          link={quizData.step[quizStates.currentQuestion].VRIphone}
          quizStates={quizStates}
          setQuizStates={setQuizStates}
        />
      ) : (
        <>
          <div>
            {quizStates.showWinFail ? (
              <motion.div
                key={`status-${quizStates.currentQuestion}`}
                variants={fadeInOut}
                initial="hidden"
                animate="visible"
                exit="exit"
                className="h-100">
                <h2>
                  {quizStates.score[quizStates.currentQuestion - 1]
                    ? "Pravilen odgovor"
                    : "Napačen odgovor"}
                </h2>

                <Button
                  label={t("next")}
                  onClick={() => {
                    setQuizStates((quizStates) => ({
                      ...quizStates,
                      showWinFail: false,
                    }));
                  }}
                  className="btn btn-sm w-100 btn-primary"
                />
              </motion.div>
            ) : (
              <motion.div
                key={`question-${quizStates.currentQuestion}`}
                variants={blurInOut}
                initial="hidden"
                animate="visible"
                exit="exit"
                className={`position-relative`}>
                {quizData.step[quizStates.currentQuestion].questionText !==
                  null && (
                  <div className="question-section mb-4">
                    <div className="question-progress">
                      <div className="description">
                        <div className="hint bg-gradient-blue-light rounded-4 p-4 mb-3 text-start">
                          <div className="text text-white display-8">
                            {
                              quizData.step[quizStates.currentQuestion]
                                .questionText
                            }
                          </div>
                        </div>
                      </div>
                    </div>

                    {quizData.step[quizStates.currentQuestion].gamePic !==
                      null && (
                      <div className="question-image position-relative">
                        <img
                          src={`https://planicar.escapebox.si/public/img/${
                            quizData.step[quizStates.currentQuestion].gamePic
                          }?v5`}
                        />
                        <div className="counter">
                          <CountdownCircleTimer
                            isPlaying
                            duration={30}
                            size={50}
                            strokeWidth={8}
                            onComplete={() => handleAnswerNoTimeLeft()}
                            trailColor="#94e3fe"
                            colors={[
                              "#004777",
                              "#F7B801",
                              "#A30000",
                              "#A30000",
                            ]}
                            colorsTime={[7, 5, 2, 0]}>
                            {({ remainingTime }) => remainingTime}
                          </CountdownCircleTimer>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                <div
                  className={`answer-section ${
                    quizStates.disableAnswer && "pe-none"
                  } ${quizStates.gameLocked && "locked"}`}>
                  {quizData.step[quizStates.currentQuestion].answerOptions.map(
                    (answerOption, index) => (
                      <button
                        key={index}
                        className={`description w-100 my-1 my-md-2 answer-option btn-answer `}
                        variant={"step-" + quizStates.currentQuestion}
                        size="lg"
                        id={"answer_" + index}
                        onClick={(e) =>
                          handleAnswerOptionClick(e, answerOption.isCorrect)
                        }>
                        {quizData.step[quizStates.currentQuestion].gamePic !==
                          null && (
                          <div id={"answer_" + index} className="w-100">
                            <svg
                              width="50"
                              height="33"
                              viewBox="0 0 50 33"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <line
                                x1="2.00024"
                                y1="33"
                                x2="2.00024"
                                y2="-3.25641e-08"
                                stroke="#095DB2"
                                strokeWidth="4"
                              />
                              <g clipPath="url(#clip0_415_1536)">
                                <path
                                  d="M5.88599 27.3327C5.88599 27.3327 8.60545 30.5015 17.1238 30.5015C25.6421 30.5015 27.8017 26.8573 34.0804 27.3327C40.3592 27.808 49.2574 31.1155 49.2574 31.1155V3.82364C49.2574 3.82364 40.3392 0.516138 34.0804 0.0408087C27.8217 -0.434521 25.6421 3.20967 17.1238 3.20967C8.60545 3.20967 5.88599 0.0408087 5.88599 0.0408087V27.293"
                                  fill={`url(#paint${index})`}
                                />
                              </g>
                              <defs>
                                <linearGradient
                                  id={`paint${index}`}
                                  x1="27.5717"
                                  y1="-0.00195313"
                                  x2="27.5717"
                                  y2="43.3135"
                                  gradientUnits="userSpaceOnUse">
                                  {index === 0 && (
                                    <>
                                      <stop
                                        className="color1"
                                        stopColor="#46B15D"
                                      />
                                      <stop
                                        className="color2"
                                        offset="1"
                                        stopColor="#A5FFB9"
                                      />
                                    </>
                                  )}

                                  {index === 1 && (
                                    <>
                                      <stop stopColor="#CC515D" />
                                      <stop offset="1" stopColor="#FFA9B2" />
                                    </>
                                  )}
                                </linearGradient>
                                <clipPath id="clip0_415_1536">
                                  <rect
                                    width="43.3714"
                                    height="31.1143"
                                    fill="white"
                                    transform="translate(5.88599)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                        )}
                        {answerOption.answerImg !== undefined && (
                          <div className="w-100 mb-2 pe-none">
                            <img src={answerOption.answerImg} />
                          </div>
                        )}
                        {answerOption.answerText}
                      </button>
                    )
                  )}
                </div>
              </motion.div>
            )}

            {gamesData.id !== "game1" && (
              <div className="question-section mb-4 mt-4">
                <div className="progress-square">
                  {quizData.step.map((status, index) => (
                    <div
                      key={index}
                      className={`sq ${
                        quizStates.score[index] === true
                          ? "correct"
                          : quizStates.score[index] === false
                          ? "incorrect"
                          : null
                      }`}></div>
                  ))}
                </div>
              </div>
            )}
          </div>

          {quizStates.gameLocked ? (
            <div className={`w-100 `}>
              <SwipeUnlock getter={quizStates} setter={isLockedClickHandler} />
            </div>
          ) : (
            <div className={`w-100 `}>
              <Button
                className="btn btn-sm btn-primary"
                onClick={isLockedClickHandler}
                label="Ponovno poglej AR prizor"
              />
            </div>
          )}
        </>
      )}
    </>
  );
}

export default Quiz;
