import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import axios from "axios";

export { PrivateRoute };

function PrivateRoute({ children }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState();

  /* FOR PRODUCTION */
  useEffect(() => {
    axios
      .get("https://planicar.escapebox.si/api/public/user", {
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then(function (response) {
        console.log(response);
        setIsLoading(false);
        setData(response.data);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
        setIsError(true);
      });
  }, []);

  if (isError) {
    return <Navigate to="/password" />;
  }

  if (
    data !== undefined &&
    data.status === "active" &&
    data.token === JSON.parse(localStorage.getItem("token"))
  ) {
    return children;
  }

  /* FOR TESTING */

  /*   return children; */
}
