import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { useLocation, useNavigate } from "react-router-dom";
import { staggerItemsFade, itemFade } from "utils/animations";

// Import styles, media and components
import Wrapper from "components/Wrapper/Wrapper";
import Button from "components/Button/Button";

import ImgLogo from "assets/images/logo.png";
import ImgPin from "assets/images/icons/pins.png";

function PreGameIntro2({ gamesData, step, setStep }) {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Wrapper className="pregame px-0">
      <motion.div variants={staggerItemsFade} initial="hidden" animate="show">
        <img src={ImgLogo} className="mb-5 w-35" alt="" />
        <motion.div variants={itemFade}>
          <p className="lead px-4">{t("G0Intro2H1")}</p>

          <p
            className="px-3"
            dangerouslySetInnerHTML={{ __html: t("G0Intro2P1") }}
          />
          <img className="w-100 mt-n5" src={ImgPin} />
          <p
            className="px-3"
            dangerouslySetInnerHTML={{ __html: t("G0Intro2P2") }}
          />
        </motion.div>

        <motion.div variants={itemFade}>
          {location.pathname === "/pravila-igre" ? (
            <Button
              onClick={() => navigate(-1)}
              label={t("next")}
              className="btn btn-primary mt-2"
            />
          ) : (
            <Button
              onClick={() => setStep(2)}
              label={t("next")}
              className="btn btn-primary mt-2"
            />
          )}
        </motion.div>
      </motion.div>
    </Wrapper>
  );
}

export default PreGameIntro2;
