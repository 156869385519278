import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

import { staggerItemsFade, itemFade } from "utils/animations";

// Import reducers
import { completedGameReducer } from "store/gameSlice";

// Import styles, media and components
import Wrapper from "components/Wrapper/Wrapper";
import Button from "components/Button/Button";
import SwipeUnlock from "components/SwipeUnlock/SwipeUnlock";

import ImgLogo from "assets/images/logo.png";
import ImgExcl from "assets/images/icons/exclamation-circle.svg";

function PreGameIntro5({ gamesData, showScreens, setShowScreens }) {
  const location = useLocation();
  const [locked, setLocked] = useState(true);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const clickHandler = () => {
    dispatch(completedGameReducer(gamesData.id));
    setShowScreens({
      ...showScreens,
      showPinOpened: false,
      showPreGameIntros: false,
    });
    navigate("../game/game1", { replace: true });
  };

  return (
    <Wrapper className="pregame  h-100">
      <motion.div variants={staggerItemsFade} initial="hidden" animate="show">
        <img src={ImgExcl} className="mb-5" alt="" />
        <motion.div className="mb-4" variants={itemFade}>
          <p className="lead">{t("SystemScreen")}</p>
          <div
            className="text-start"
            dangerouslySetInnerHTML={{ __html: t("SystemScreenP") }}></div>
        </motion.div>

        {location.pathname === "/sistemska-obvestila" ? (
          <Button
            onClick={() => navigate(-1)}
            label={t("next")}
            className="btn btn-primary mt-2"
          />
        ) : (
          <Button
            onClick={clickHandler}
            label={t("next")}
            className="btn btn-primary mt-2"
          />
        )}
      </motion.div>
    </Wrapper>
  );
}

export default PreGameIntro5;
