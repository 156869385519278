import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { staggerItemsFade, itemFade } from "utils/animations";
import watermark from "watermarkjs";

// Import styles, media and components
import Wrapper from "components/Wrapper/Wrapper";
import Button from "components/Button/Button";

import imgLogo from "assets/images/logo_mini.png";

function SelfieShowPhoto({
  step,
  setStep,
  photo,
  setWatermarkPhoto,
  watermarkPhoto,
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    watermark([photo, imgLogo])
      .image(watermark.image.lowerRight(1))
      .then((img) => setWatermarkPhoto(img.currentSrc));
  }, []);

  return (
    <Wrapper className="lang-picker">
      <p
        className="lead mb-5"
        dangerouslySetInnerHTML={{
          __html: t("selfieH"),
        }}
      />

      <img className="w-100" src={watermarkPhoto} alt="selfie" />

      <div className="w-100">
        <Button
          onClick={() => setStep(3)}
          label={t("confirm")}
          className="btn btn-primary mt-5 mb-2"
        />
      </div>

      <a className="w-100" onClick={() => setStep(1)} href="#c">
        {t("selfieagain")}
      </a>
    </Wrapper>
  );
}

export default SelfieShowPhoto;
