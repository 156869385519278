import { useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { GameContext } from "../GameContext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Import styles, media and components

import PreGameIntro1 from "./PreGameIntro1/PreGameIntro1";
import PreGameIntro2 from "./PreGameIntro2/PreGameIntro2";
import PreGameIntro3 from "./PreGameIntro3/PreGameIntro3";
import PreGameIntro4 from "./PreGameIntro4/PreGameIntro4";
import PreGameIntro5 from "./PreGameIntro5/PreGameIntro5";

function PreGameIntro({ gamesData }) {
  const [step, setStep] = useState(0);

  const { showScreens, setShowScreens } = useContext(GameContext);

  return (
    <>
      {step === 0 && <PreGameIntro1 step={step} setStep={setStep} />}
      {step === 1 && <PreGameIntro2 step={step} setStep={setStep} />}
      {step === 2 && <PreGameIntro3 step={step} setStep={setStep} />}
      {step === 3 && <PreGameIntro4 step={step} setStep={setStep} />}
      {step === 4 && (
        <PreGameIntro5
          showScreens={showScreens}
          setShowScreens={setShowScreens}
          gamesData={gamesData}
        />
      )}
    </>
  );
}

export default PreGameIntro;
