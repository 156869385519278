function SvgStars() {
  return (
    <svg
      className="star"
      width="52"
      height="58"
      viewBox="0 0 52 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="zvezdice">
        <g id="Vector" filter="url(#filter0_d_279_17)">
          <path
            d="M22.5306 10.2705L26.1532 17.7516L34.3869 18.8749C34.7366 18.9276 35.0513 19.1168 35.2618 19.401C35.4724 19.6853 35.5616 20.0414 35.5101 20.3913C35.4806 20.7011 35.3315 20.9872 35.0945 21.1889L29.0962 26.9064L30.5789 35.0783C30.6269 35.3292 30.6029 35.5885 30.5098 35.8263C30.4167 36.0641 30.2582 36.2707 30.0526 36.4222C29.8471 36.5738 29.6028 36.664 29.3481 36.6827C29.0934 36.7013 28.8386 36.6474 28.6132 36.5274L21.3118 32.5959L14.0105 36.5274C13.6935 36.6926 13.3241 36.7263 12.9825 36.6212C12.6409 36.516 12.3544 36.2806 12.1851 35.9657C12.0218 35.6717 11.9719 35.328 12.0447 34.9997L13.5443 26.9177L7.53469 21.2001C7.40315 21.0778 7.29704 20.9308 7.22243 20.7674C7.14783 20.6041 7.10621 20.4276 7.09995 20.2481C7.09369 20.0686 7.12292 19.8896 7.18596 19.7215C7.249 19.5533 7.34459 19.3992 7.46729 19.2681C7.69693 19.0593 7.98512 18.926 8.29293 18.8861L16.4985 17.7629L20.1155 10.2705C20.2761 9.95419 20.5532 9.71245 20.8884 9.59618C21.2235 9.47991 21.5908 9.49818 21.9128 9.64712C22.1822 9.78054 22.3996 9.99988 22.5306 10.2705Z"
            fill="url(#paint0_linear_279_17)"
          />
        </g>
        <g id="Vector_2" filter="url(#filter1_d_279_17)">
          <path
            d="M8.64116 0.322788L10.2002 3.54239L13.7437 4.02582C13.8942 4.0485 14.0296 4.12992 14.1203 4.25224C14.2109 4.37457 14.2493 4.52783 14.2271 4.67843C14.2144 4.81175 14.1503 4.93488 14.0483 5.02167L11.4668 7.48229L12.1049 10.9992C12.1256 11.1072 12.1152 11.2188 12.0752 11.3211C12.0351 11.4234 11.9669 11.5124 11.8784 11.5776C11.7899 11.6428 11.6848 11.6816 11.5752 11.6897C11.4656 11.6977 11.3559 11.6745 11.2589 11.6228L8.11665 9.93084L4.9744 11.6228C4.83798 11.6939 4.67904 11.7084 4.532 11.6632C4.38497 11.6179 4.26168 11.5166 4.18884 11.3811C4.11856 11.2546 4.09707 11.1067 4.12841 10.9654L4.77377 7.48713L2.18745 5.0265C2.13084 4.97388 2.08518 4.9106 2.05307 4.84029C2.02096 4.76998 2.00305 4.69403 2.00036 4.61678C1.99766 4.53954 2.01024 4.46252 2.03737 4.39014C2.0645 4.31776 2.10564 4.25145 2.15845 4.19501C2.25728 4.10515 2.38131 4.04778 2.51377 4.03065L6.04518 3.54722L7.60181 0.322788C7.67093 0.186642 7.79016 0.082607 7.93441 0.0325685C8.07867 -0.0174699 8.23671 -0.00960816 8.37529 0.0544914C8.49126 0.11191 8.5848 0.206305 8.64116 0.322788Z"
            fill="#FBD977"
          />
        </g>
        <g id="Vector_3" filter="url(#filter2_d_279_17)">
          <path
            d="M17.564 40.7485L19.3681 44.4741L23.4685 45.0335C23.6427 45.0598 23.7994 45.154 23.9042 45.2955C24.0091 45.4371 24.0536 45.6144 24.0279 45.7887C24.0132 45.943 23.939 46.0854 23.8209 46.1859L20.8337 49.0332L21.5721 53.1029C21.5961 53.2278 21.5841 53.3569 21.5377 53.4753C21.4914 53.5938 21.4124 53.6967 21.3101 53.7721C21.2077 53.8476 21.086 53.8925 20.9592 53.9018C20.8323 53.9111 20.7055 53.8843 20.5932 53.8245L16.9571 51.8666L13.321 53.8245C13.1632 53.9068 12.9792 53.9235 12.8091 53.8712C12.6389 53.8188 12.4963 53.7016 12.412 53.5448C12.3307 53.3984 12.3058 53.2272 12.3421 53.0637L13.0889 49.0388L10.0961 46.1915C10.0306 46.1306 9.97771 46.0574 9.94056 45.976C9.90341 45.8946 9.88268 45.8067 9.87956 45.7174C9.87645 45.628 9.891 45.5388 9.9224 45.4551C9.95379 45.3713 10.0014 45.2946 10.0625 45.2293C10.1769 45.1253 10.3204 45.0589 10.4737 45.0391L14.5601 44.4797L16.3613 40.7485C16.4413 40.591 16.5793 40.4706 16.7462 40.4127C16.9131 40.3548 17.096 40.3639 17.2564 40.4381C17.3906 40.5045 17.4988 40.6137 17.564 40.7485Z"
            fill="#FBD977"
          />
        </g>
        <g id="Vector_4" filter="url(#filter3_d_279_17)">
          <path
            d="M46.0286 8.61617L46.8963 10.408L48.8684 10.6771C48.9521 10.6897 49.0275 10.735 49.0779 10.8031C49.1284 10.8711 49.1497 10.9564 49.1374 11.0403C49.1303 11.1145 49.0946 11.183 49.0379 11.2313L47.6012 12.6007L47.9563 14.558C47.9678 14.6181 47.9621 14.6802 47.9398 14.7372C47.9174 14.7941 47.8795 14.8436 47.8302 14.8799C47.781 14.9162 47.7225 14.9378 47.6615 14.9423C47.6005 14.9467 47.5395 14.9338 47.4855 14.9051L45.7367 13.9634L43.9879 14.9051C43.912 14.9447 43.8235 14.9527 43.7417 14.9276C43.6599 14.9024 43.5912 14.846 43.5507 14.7706C43.5116 14.7001 43.4996 14.6178 43.5171 14.5392L43.8762 12.6034L42.4368 11.234C42.4053 11.2047 42.3799 11.1695 42.3621 11.1303C42.3442 11.0912 42.3342 11.0489 42.3327 11.0059C42.3312 10.963 42.3382 10.9201 42.3533 10.8798C42.3684 10.8395 42.3913 10.8026 42.4207 10.7712C42.4757 10.7212 42.5447 10.6893 42.6185 10.6797L44.5838 10.4107L45.4502 8.61617C45.4886 8.5404 45.555 8.4825 45.6353 8.45465C45.7155 8.4268 45.8035 8.43118 45.8806 8.46685C45.9452 8.49881 45.9972 8.55134 46.0286 8.61617Z"
            fill="url(#paint1_linear_279_17)"
          />
        </g>
        <g id="Vector_5" filter="url(#filter4_d_279_17)">
          <path
            d="M40.7014 32.6582L41.5691 34.45L43.5412 34.719C43.625 34.7317 43.7004 34.777 43.7508 34.8451C43.8012 34.9131 43.8226 34.9984 43.8103 35.0823C43.8032 35.1565 43.7675 35.225 43.7107 35.2733L42.274 36.6427L42.6292 38.6C42.6407 38.6601 42.6349 38.7222 42.6126 38.7792C42.5903 38.8361 42.5523 38.8856 42.5031 38.9219C42.4539 38.9582 42.3954 38.9798 42.3343 38.9843C42.2733 38.9887 42.2123 38.9758 42.1583 38.9471L40.4095 38.0054L38.6607 38.9471C38.5848 38.9867 38.4964 38.9947 38.4145 38.9695C38.3327 38.9444 38.2641 38.888 38.2235 38.8126C38.1844 38.7421 38.1725 38.6598 38.1899 38.5812L38.5491 36.6454L37.1097 35.276C37.0782 35.2467 37.0528 35.2115 37.0349 35.1723C37.017 35.1332 37.0071 35.0909 37.0056 35.0479C37.0041 35.005 37.0111 34.9621 37.0262 34.9218C37.0413 34.8815 37.0642 34.8446 37.0936 34.8132C37.1486 34.7632 37.2176 34.7313 37.2913 34.7217L39.2567 34.4527L40.123 32.6582C40.1615 32.5824 40.2278 32.5245 40.3081 32.4966C40.3884 32.4688 40.4763 32.4732 40.5535 32.5088C40.618 32.5408 40.6701 32.5933 40.7014 32.6582Z"
            fill="url(#paint2_linear_279_17)"
          />
        </g>
        <g id="Vector_6" filter="url(#filter5_d_279_17)">
          <path
            d="M30.8043 5.94621L31.2945 6.95846L32.4085 7.11045C32.4559 7.11758 32.4984 7.14318 32.5269 7.18164C32.5554 7.2201 32.5675 7.26828 32.5605 7.31563C32.5565 7.35755 32.5364 7.39626 32.5043 7.42355L31.6927 8.19717L31.8933 9.30289C31.8998 9.33683 31.8966 9.37192 31.884 9.40409C31.8714 9.43627 31.8499 9.46423 31.8221 9.48473C31.7943 9.50524 31.7612 9.51745 31.7268 9.51997C31.6923 9.52249 31.6578 9.5152 31.6273 9.49896L30.6394 8.967L29.6515 9.49896C29.6086 9.52131 29.5586 9.52587 29.5124 9.51165C29.4661 9.49742 29.4274 9.46556 29.4045 9.42296C29.3824 9.38318 29.3756 9.33668 29.3855 9.29225L29.5884 8.19869L28.7752 7.42507C28.7574 7.40852 28.7431 7.38863 28.733 7.36652C28.7229 7.34441 28.7173 7.32054 28.7164 7.29625C28.7156 7.27196 28.7195 7.24775 28.7281 7.22499C28.7366 7.20224 28.7495 7.18139 28.7661 7.16364C28.7972 7.13539 28.8362 7.11736 28.8778 7.11197L29.9881 6.95998L30.4775 5.94621C30.4993 5.90341 30.5367 5.8707 30.5821 5.85497C30.6274 5.83923 30.6771 5.84171 30.7207 5.86186C30.7572 5.87991 30.7866 5.90959 30.8043 5.94621Z"
            fill="url(#paint3_linear_279_17)"
          />
        </g>
        <g id="Vector_7" filter="url(#filter6_d_279_17)">
          <path
            d="M39.0934 0.806563L39.5835 1.81881L40.6976 1.9708C40.7449 1.97793 40.7875 2.00353 40.816 2.04199C40.8445 2.08045 40.8566 2.12863 40.8496 2.17598C40.8456 2.2179 40.8254 2.25661 40.7934 2.2839L39.9817 3.05752L40.1824 4.16324C40.1889 4.19719 40.1856 4.23227 40.173 4.26445C40.1604 4.29662 40.139 4.32458 40.1112 4.34508C40.0833 4.36559 40.0503 4.3778 40.0158 4.38032C39.9814 4.38284 39.9469 4.37555 39.9164 4.35931L38.9285 3.82735L37.9405 4.35931C37.8976 4.38166 37.8477 4.38622 37.8014 4.372C37.7552 4.35778 37.7164 4.32591 37.6935 4.28331C37.6714 4.24353 37.6647 4.19703 37.6745 4.1526L37.8774 3.05904L37.0643 2.28542C37.0465 2.26887 37.0322 2.24898 37.0221 2.22687C37.012 2.20477 37.0063 2.18089 37.0055 2.1566C37.0046 2.13231 37.0086 2.1081 37.0171 2.08534C37.0257 2.06259 37.0386 2.04174 37.0552 2.024C37.0863 1.99574 37.1253 1.97771 37.1669 1.97232L38.2772 1.82033L38.7666 0.806563C38.7883 0.763759 38.8258 0.73105 38.8712 0.715318C38.9165 0.699586 38.9662 0.702057 39.0098 0.72221C39.0462 0.740263 39.0756 0.769941 39.0934 0.806563Z"
            fill="url(#paint4_linear_279_17)"
          />
        </g>
        <g id="Vector_8" filter="url(#filter7_d_279_17)">
          <path
            d="M32.5875 45.4032L33.0777 46.4155L34.1917 46.5675C34.2391 46.5746 34.2816 46.6002 34.3101 46.6387C34.3386 46.6771 34.3507 46.7253 34.3437 46.7727C34.3397 46.8146 34.3196 46.8533 34.2875 46.8806L33.4759 47.6542L33.6765 48.7599C33.683 48.7939 33.6798 48.8289 33.6672 48.8611C33.6546 48.8933 33.6331 48.9213 33.6053 48.9418C33.5775 48.9623 33.5444 48.9745 33.51 48.977C33.4755 48.9795 33.441 48.9722 33.4105 48.956L32.4226 48.424L31.4347 48.956C31.3918 48.9783 31.3418 48.9829 31.2956 48.9687C31.2493 48.9545 31.2106 48.9226 31.1877 48.88C31.1656 48.8402 31.1588 48.7937 31.1687 48.7493L31.3716 47.6557L30.5584 46.8821C30.5406 46.8656 30.5263 46.8457 30.5162 46.8236C30.5061 46.8014 30.5005 46.7776 30.4996 46.7533C30.4988 46.729 30.5027 46.7048 30.5113 46.682C30.5198 46.6593 30.5327 46.6384 30.5493 46.6207C30.5804 46.5924 30.6194 46.5744 30.661 46.569L31.7713 46.417L32.2607 45.4032C32.2825 45.3604 32.3199 45.3277 32.3653 45.312C32.4107 45.2963 32.4603 45.2987 32.5039 45.3189C32.5404 45.3369 32.5698 45.3666 32.5875 45.4032Z"
            fill="url(#paint5_linear_279_17)"
          />
        </g>
        <g id="Vector_9" filter="url(#filter8_d_279_17)">
          <path
            d="M40.5743 19.5292L41.0645 20.5415L42.1786 20.6935C42.2259 20.7006 42.2685 20.7262 42.297 20.7646C42.3254 20.8031 42.3375 20.8513 42.3306 20.8986C42.3266 20.9406 42.3064 20.9793 42.2743 21.0066L41.4627 21.7802L41.6633 22.8859C41.6698 22.9198 41.6666 22.9549 41.654 22.9871C41.6414 23.0193 41.6199 23.0472 41.5921 23.0677C41.5643 23.0882 41.5312 23.1005 41.4968 23.103C41.4623 23.1055 41.4278 23.0982 41.3973 23.082L40.4094 22.55L39.4215 23.082C39.3786 23.1043 39.3286 23.1089 39.2824 23.0947C39.2362 23.0804 39.1974 23.0486 39.1745 23.006C39.1524 22.9662 39.1456 22.9197 39.1555 22.8753L39.3584 21.7817L38.5453 21.0081C38.5275 20.9915 38.5131 20.9716 38.503 20.9495C38.4929 20.9274 38.4873 20.9035 38.4864 20.8793C38.4856 20.855 38.4895 20.8308 38.4981 20.808C38.5066 20.7852 38.5195 20.7644 38.5361 20.7467C38.5672 20.7184 38.6062 20.7004 38.6479 20.695L39.7581 20.543L40.2475 19.5292C40.2693 19.4864 40.3068 19.4537 40.3521 19.438C40.3975 19.4222 40.4472 19.4247 40.4907 19.4449C40.5272 19.4629 40.5566 19.4926 40.5743 19.5292Z"
            fill="url(#paint6_linear_279_17)"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_279_17"
          x="5.09912"
          y="9.52051"
          width="32.4253"
          height="31.166"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_279_17"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_279_17"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_279_17"
          x="0"
          y="0"
          width="16.2332"
          height="15.6914"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_279_17"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_279_17"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_279_17"
          x="7.87915"
          y="40.375"
          width="18.1558"
          height="17.5283"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_279_17"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_279_17"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_d_279_17"
          x="40.3325"
          y="8.43652"
          width="10.8083"
          height="10.5068"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_279_17"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_279_17"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_d_279_17"
          x="35.0054"
          y="32.4785"
          width="10.8083"
          height="10.5068"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_279_17"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_279_17"
            result="shape"
          />
        </filter>
        <filter
          id="filter5_d_279_17"
          x="26.7163"
          y="5.84473"
          width="7.84619"
          height="7.67578"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_279_17"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_279_17"
            result="shape"
          />
        </filter>
        <filter
          id="filter6_d_279_17"
          x="35.0054"
          y="0.705078"
          width="7.84619"
          height="7.67578"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_279_17"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_279_17"
            result="shape"
          />
        </filter>
        <filter
          id="filter7_d_279_17"
          x="28.4995"
          y="45.3018"
          width="7.84619"
          height="7.67578"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_279_17"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_279_17"
            result="shape"
          />
        </filter>
        <filter
          id="filter8_d_279_17"
          x="36.4863"
          y="19.4277"
          width="7.84619"
          height="7.67578"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_279_17"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_279_17"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_279_17"
          x1="21.3118"
          y1="9.52051"
          x2="22.6945"
          y2="42.0793"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#65F52B" />
          <stop offset="0.0001" stopColor="#FFFFBA" />
          <stop offset="1" stopColor="#F5AE2B" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_279_17"
          x1="45.7367"
          y1="8.43652"
          x2="46.0679"
          y2="16.2349"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#65F52B" />
          <stop offset="0.0001" stopColor="#FFFFBA" />
          <stop offset="1" stopColor="#F5AE2B" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_279_17"
          x1="40.4095"
          y1="32.4785"
          x2="40.7407"
          y2="40.2769"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#65F52B" />
          <stop offset="0.0001" stopColor="#FFFFBA" />
          <stop offset="1" stopColor="#F5AE2B" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_279_17"
          x1="30.6394"
          y1="5.84473"
          x2="30.8265"
          y2="10.2502"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#65F52B" />
          <stop offset="0.0001" stopColor="#FFFFBA" />
          <stop offset="1" stopColor="#F5AE2B" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_279_17"
          x1="38.9284"
          y1="0.705078"
          x2="39.1155"
          y2="5.11053"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#65F52B" />
          <stop offset="0.0001" stopColor="#FFFFBA" />
          <stop offset="1" stopColor="#F5AE2B" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_279_17"
          x1="32.4226"
          y1="45.3018"
          x2="32.6097"
          y2="49.7072"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#65F52B" />
          <stop offset="0.0001" stopColor="#FFFFBA" />
          <stop offset="1" stopColor="#F5AE2B" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_279_17"
          x1="40.4094"
          y1="19.4277"
          x2="40.5965"
          y2="23.8332"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#65F52B" />
          <stop offset="0.0001" stopColor="#FFFFBA" />
          <stop offset="1" stopColor="#F5AE2B" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgStars;
