import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Import styles, media and components
import Wrapper from "components/Wrapper/Wrapper";
import Button from "components/Button/Button";

import ImgLogo from "assets/images/logo.png";

function PreGameIntro1({ gamesData, step, setStep }) {
  const { t } = useTranslation();

  return (
    <Wrapper className="pregame">
      <img src={ImgLogo} className="mb-5 w-35" alt="" />
      <p className="lead">{t("G0Intro1H1")}</p>
      <p>{t("G0Intro1P1")}</p>
      <p>{t("G0Intro1P2")}</p>

      <Button
        onClick={() => setStep(1)}
        label={t("next")}
        className="btn btn-primary mt-3"
      />
    </Wrapper>
  );
}

export default PreGameIntro1;
