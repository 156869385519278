import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { GameContext } from "../GameContext";

// Import styles, media and components
import Wrapper from "components/Wrapper/Wrapper";
import Offcanvas from "react-bootstrap/Offcanvas";

import ImgLogo from "assets/images/logo.png";
import imgBack from "assets/images/icons/back.svg";
import imgInfo from "assets/images/icons/info.svg";
import imgLock from "assets/images/icons/lock.svg";

const iconsData = [
  {
    id: 1,
    name: "1",
  },
  {
    id: 2,
    name: "2",
  },
  {
    id: 3,
    name: "3",
  },
  {
    id: 4,
    name: "4",
  },
  {
    id: 5,
    name: "5",
  },
  {
    id: 6,
    name: "6",
  },
];

function arrayEquals(a, b) {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  );
}

function GamePassword({ gamesData }) {
  const [pickedIcons, setPickedIcons] = useState({
    icons: [],
    response: [],
  });
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const { showScreens, setShowScreens } = useContext(GameContext);

  const handleChange = (e) => {
    // Destructuring
    const { value, checked } = e.target;
    const { icons } = pickedIcons;

    // Case 1 : The user checks the box
    if (checked) {
      setPickedIcons({
        icons: [...icons, value],
        response: [...icons, value],
      });
    }

    // Case 2  : The user unchecks the box
    else {
      setPickedIcons({
        icons: icons.filter((e) => e !== value),
        response: icons.filter((e) => e !== value),
      });
    }
  };

  useEffect(() => {
    if (pickedIcons.icons.length > 2) {
      setDisabled(true);
      setTimeout(() => {
        if (arrayEquals(pickedIcons.icons, gamesData.pass)) {
          setShowScreens({ ...showScreens, showGamePassword: false });
        } else {
          setDisabled(false);
          setPickedIcons({
            icons: [],
            response: [],
          });
        }
      }, 150);
    }
  }, [pickedIcons]);

  return (
    <Wrapper className="game-password minh-100 px-0">
      <img src={ImgLogo} className="mb-5 w-35" alt="" />

      <p className="lead px-3">{t("EnterPass")}</p>

      <form className={`password ${gamesData.id}`}>
        <div className="form-floating mt-3 mb-3 text-center">
          <div className="status">
            {gamesData.pass.map((item, index) => (
              <div
                key={index}
                className={`position-relative item_${pickedIcons.response[index]}`}>
                {pickedIcons.response[index] !== undefined ? (
                  <>
                    <img src={imgLock} />
                  </>
                ) : null}
              </div>
            ))}
          </div>
        </div>

        <div className="row g-4 mt-3 keyboard mb-3">
          {iconsData.map((item, index) => (
            <div className="col-4" key={index}>
              <label>
                <input
                  type="checkbox"
                  name="icons"
                  value={item.id}
                  onChange={handleChange}
                  disabled={disabled}
                  checked={pickedIcons.icons.includes(item.name)}
                />
                <span
                  className={`position-relative d-flex align-items-center justify-content-center item_${item.id}`}>
                  <img src={imgLock} />
                </span>
              </label>
            </div>
          ))}
        </div>
      </form>

      <img className="button-back" onClick={() => navigate(-1)} src={imgBack} />
      <img className="button-info" onClick={handleShow} src={imgInfo} />

      <Offcanvas
        show={show}
        placement="end"
        id="offcanvas"
        onHide={handleClose}>
        <div className="bg-gradient-blue-light h-100 pb-5">
          <div class="offcanvas-header">
            <button
              type="button"
              class="btn-close"
              onClick={() => setShow(false)}
              data-bs-dismiss="offcanvas"
              aria-label="Close"></button>
          </div>
          <img className="logo" src={ImgLogo} />
          <div className="content text-center px-3 mt-6">
            <p className="lead text-white">{t("PasshintH")}</p>
            <p className="text-white lead fw-normal">{t("PasshintP")}</p>
          </div>
        </div>
      </Offcanvas>
    </Wrapper>
  );
}

export default GamePassword;
