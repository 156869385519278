// Import React stuff
import { Routes, Route, useLocation } from "react-router-dom";
import { useEffect, useState, useMemo } from "react";
import { AnimatePresence } from "framer-motion";
import { useSelector } from "react-redux";
import "i18n/config";
import { useTranslation } from "react-i18next";
import { GameContext } from "views/Game/GameContext";
import { PrivateRoute } from "components/PrivateRoute";
import { QueryClient, QueryClientProvider } from "react-query";

// Import initial state
import initialState from "InitialState";

// Styles
import "assets/styles/style.scss";

// Import views and components
import Start from "views/Start/Start";
import StartLogo from "views/Start/StartLogo";
import LangPicker from "views/LangPicker/LangPicker";
import Landing from "views/Landing/Landing";
import Password from "views/Password/Password";
import Loader from "views/Loader/Loader";
import Map from "views/Map/Map";
import Game from "views/Game/Game";
import Heroes from "views/Heroes/Heroes";
import Selfie from "views/Selfie/Selfie";
import FinishScreen from "views/FinishScreen/FinishScreen";
import Reset from "views/Reset";

import PreGameIntro1 from "views/Game/PreGameIntro/PreGameIntro1/PreGameIntro1";
import PreGameIntro2 from "views/Game/PreGameIntro/PreGameIntro2/PreGameIntro2";
import PreGameIntro3 from "views/Game/PreGameIntro/PreGameIntro3/PreGameIntro3";
import PreGameIntro4 from "views/Game/PreGameIntro/PreGameIntro4/PreGameIntro4";
import PreGameIntro5 from "views/Game/PreGameIntro/PreGameIntro5/PreGameIntro5";

// Import media

const queryClient = new QueryClient();

function App() {
  // Imported initial states I need to reset them on map screen
  const [showScreens, setShowScreens] = useState(initialState);

  const value = useMemo(
    () => ({ showScreens, setShowScreens }),
    [showScreens, setShowScreens]
  );

  const location = useLocation();
  const { i18n } = useTranslation();
  const routeName = location.pathname.replace(/\//g, "");

  // get language from persistent storage
  const selectedLanguage = useSelector((state) => state.game.selectedLanguage);

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
    //window.addEventListener("resize", documentHeight);
    //documentHeight();
  }, []);

  return (
    <div className={`app ${routeName}`}>
      <AnimatePresence exitBeforeEnter>
        <QueryClientProvider client={queryClient}>
          <GameContext.Provider value={value}>
            <Routes location={location} key={location.pathname}>
              <Route path="/" element={<Start />} />
              <Route path="/logo" element={<StartLogo />} />
              <Route path="/language" element={<LangPicker />} />
              <Route path="/lp" element={<Landing />} />
              <Route path="/password" element={<Password />} />
              <Route
                path="/loader"
                element={
                  <PrivateRoute>
                    <Loader />
                  </PrivateRoute>
                }
              />
              <Route
                path="/map"
                element={
                  <PrivateRoute>
                    <Map />
                  </PrivateRoute>
                }
              />
              <Route
                path="game/:game"
                element={
                  <PrivateRoute>
                    <Game />
                  </PrivateRoute>
                }
              />
              <Route
                path="/heroes"
                element={
                  <PrivateRoute>
                    <Heroes />
                  </PrivateRoute>
                }
              />
              <Route
                path="/selfie"
                element={
                  <PrivateRoute>
                    <Selfie />
                  </PrivateRoute>
                }
              />
              <Route
                path="/finish"
                element={
                  <PrivateRoute>
                    <FinishScreen />
                  </PrivateRoute>
                }
              />

              <Route path="/pravila-igre" element={<PreGameIntro2 />} />
              <Route path="/kako-deluje-ar" element={<PreGameIntro3 />} />
              <Route path="/sistemska-obvestila" element={<PreGameIntro5 />} />
              <Route path="/reset" element={<Reset />} />
            </Routes>
          </GameContext.Provider>
        </QueryClientProvider>
      </AnimatePresence>
    </div>
  );
}

export default App;
