import { useState, useContext } from "react";
import { GameContext } from "../GameContext";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Import styles, media and components
import Wrapper from "components/Wrapper/Wrapper";
import Button from "components/Button/Button";

import ImgSedeznica from "assets/images/sedeznica.jpg";

function GameBadge({ gamesData }) {
  const [extraStep, setExtraStep] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const completedGame = useSelector((state) => state.game.completedGame);

  const clickHandler = () => {
    navigate("../map", { replace: true });
  };

  const clickHandlerExtraStep = () => {
    setExtraStep(true);
  };

  return (
    <>
      {extraStep ? (
        <Wrapper className="game-badge game-brief pt-4 minh-100 bg-white">
          <div className="w-100">
            <div
              className="text-white pb-0"
              dangerouslySetInnerHTML={{
                __html: t("G4BriefExtraHead"),
              }}></div>
            <img src={ImgSedeznica} className="my-2 w-100 rounded-4" alt="" />
          </div>
          <div className="bg-gradient-blue-dark rounded-4 p-4 text-start">
            <p className="heading display-8">{t("mission")}</p>
            <div
              className="text-white pb-0"
              dangerouslySetInnerHTML={{
                __html: t("G4BriefExtra"),
              }}></div>
          </div>
          <Button
            onClick={clickHandler}
            label={t("next")}
            className="btn btn-primary  mt-4"
          />
        </Wrapper>
      ) : (
        <Wrapper className="game-badge minh-100 bg-gradient-blue">
          <p className="text-white display-8 px-5">{gamesData.badgeDesc}</p>

          <div className="w-100">
            <img
              src={`https://planicar.escapebox.si/public/img/${gamesData.badgeUrl}?v3`}
              className={`my-3 rounded-circle w-75 ${gamesData.id}`}
              alt=""
            />

            <p className="display-8 text-white">{gamesData.badgeDesc2}</p>
          </div>

          {completedGame[4].completed && !completedGame[5].completed ? (
            <Button
              onClick={clickHandlerExtraStep}
              label={t("continue")}
              className="btn btn-primary  mt-4"
            />
          ) : (
            <Button
              onClick={clickHandler}
              label={t("continue")}
              className="btn btn-primary  mt-4"
            />
          )}
        </Wrapper>
      )}
    </>
  );
}

export default GameBadge;
