import { useState, useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { GameContext } from "views/Game/GameContext";
import { useTranslation } from "react-i18next";

// Import styles, media and components
import Quiz from "components/Quiz/Quiz";
import Wrapper from "components/Wrapper/Wrapper";
import Button from "components/Button/Button";

import VR from "views/Game/VR/VR";
import InfoHearing from "views/Info/InfoHearing";
import SwipeUnlock from "components/SwipeUnlock/SwipeUnlock";

import ImgBloudek from "assets/images/bloudek.jpg";
import imgARbg from "assets/images/AR1.png";
import imgPreberi from "assets/images/icons/preberi.svg";

function Game4({ gamesData }) {
  const [openHearing, setOpenHearing] = useState(false);

  const [quizStates, setQuizStates] = useState({
    currentQuestion: 0,
    score: [],
    disableAnswer: false,
    showIntro: false,
    showWinFail: false,
    showVR: false,
    showHearing: false,
    gameLocked: false,
  });

  const { t } = useTranslation();

  const { showScreens, setShowScreens } = useContext(GameContext);

  const isLockedClickHandler = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      setShowScreens({
        ...showScreens,
        gameLocked: false,
      });

      window.open(t("G4VR1Android"), "_blank");
    } // iOS detection from: http://stackoverflow.com/a/9039885/177710
    else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setShowScreens({
        ...showScreens,
        showVR: true,
      });
    } else {
      setShowScreens({
        ...showScreens,
        gameLocked: false,
        showVR: true,
      });
    }

    //dispatch(completedGameReducer(gamesData.id));
  };

  const quizData = {
    step: [
      {
        gamePic: null,
        triviaPic: "vpr1trivia.png",
        triviaTxt: t("G2question1trivia"),
        question: "Navodila1...",
        questionText: null,
        winTxt: null,
        failtxt: null,
        hasIntro: false,
        hasVR: true,
        VRAndroid: t("G4VR1Android"),
        VRIphone: t("G4VR1Iphone"),
        answerOptions: [
          { answerText: t("Stanko Bloudek"), isCorrect: true },
          { answerText: t("Peter Prevc"), isCorrect: false },
        ],
      },
    ],
  };

  return (
    <>
      {openHearing ? (
        <InfoHearing setOpenHearing={setOpenHearing} />
      ) : showScreens.showVR ? (
        <VR img={imgARbg} link={t("G1VR4Iphone")} />
      ) : (
        <Wrapper className={`game4 minh-100 text-center`}>
          <div className={showScreens.gameLocked ? "locked" : "unlocked"}>
            <div className="w-100 mb-3">
              <img src={ImgBloudek} />
            </div>

            <div
              dangerouslySetInnerHTML={{
                __html: t("G4quiztxt"),
              }}></div>

            {!showScreens.gameLocked && (
              <div className="px-4 quiz">
                <Quiz
                  gamesData={gamesData}
                  quizData={quizData}
                  quizStates={quizStates}
                  setQuizStates={setQuizStates}
                />
              </div>
            )}

            <div
              style={{ cursor: "pointer" }}
              className="mt-3 "
              onClick={() => setOpenHearing(true)}>
              <img src={imgPreberi} />
              <p className="fw-bold">{t("G4hearingread")}</p>
            </div>
          </div>
          <div
            className={`w-100 ${
              showScreens.gameLocked ? "d-block" : "d-none"
            }`}>
            <SwipeUnlock getter={showScreens} setter={isLockedClickHandler} />
          </div>
        </Wrapper>
      )}
    </>
  );
}

export default Game4;
