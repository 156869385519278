import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthed: false,
  completedGame: [
    {
      id: "gamestart",
      completed: false,
    },
    {
      id: "game1",
      completed: false,
    },
    {
      id: "game2",
      completed: false,
    },
    {
      id: "game3",
      completed: false,
    },
    {
      id: "game4",
      completed: false,
    },
    {
      id: "game5",
      completed: false,
    },
    {
      id: "game6",
      completed: false,
    },
  ],
  selectedLanguage: "",
};

export const gameSlice = createSlice({
  name: "game",
  initialState,
  reducers: {
    isAuthedReducer: (state, action) => {
      state.isAuthed = action.payload;
    },
    completedGameReducer: (state, action) => {
      const game = state.completedGame.find((e) => e.id === action.payload);

      if (game) {
        game.completed = true;
      }
    },
    resetGamesReducer: (state, action) => {
      return initialState;
    },
    selectedLanguageReducer: (state, action) => {
      state.selectedLanguage = action.payload;
    },
  },
});

export const {
  isAuthedReducer,
  completedGameReducer,
  resetGamesReducer,
  selectedLanguageReducer,
} = gameSlice.actions;

export default gameSlice.reducer;
