import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { GameContext } from "views/Game/GameContext";
import { useTranslation } from "react-i18next";

// Import styles, media and components
import Quiz from "components/Quiz/Quiz";
import Wrapper from "components/Wrapper/Wrapper";

function Game2({ gamesData }) {
  const [quizStates, setQuizStates] = useState({
    currentQuestion: 0,
    score: [],
    disableAnswer: false,
    showIntro: false,
    showWinFail: false,
    showVR: false,
    gameLocked: true,
  });

  const { t } = useTranslation();

  const quizData = {
    step: [
      {
        gamePic: null,
        triviaPic: "vpr1trivia.png",
        triviaTxt: t("G2question1trivia"),
        question: "Navodila1...",
        questionText: t("G2question1txt"),
        winTxt: null,
        failtxt: null,
        hasIntro: false,
        hasVR: true,
        VRAndroid: t("G2VR1Android"),
        VRIphone: t("G2VR1Iphone"),
        answerOptions: [
          { answerText: t("G2question1opt1"), isCorrect: true },
          { answerText: t("G2question1opt2"), isCorrect: false },
          { answerText: t("G2question1opt3"), isCorrect: false },
        ],
      },
      {
        gamePic: null,
        triviaPic: "vpr2trivia.jpg",
        triviaTxt: t("G2question2trivia"),
        question: "Navodila2...",
        questionText: t("G2question2txt"),
        winTxt: null,
        failtxt: null,
        hasIntro: false,
        hasVR: true,
        VRAndroid: t("G2VR2Android"),
        VRIphone: t("G2VR2Iphone"),
        answerOptions: [
          { answerText: t("G2question2opt1"), isCorrect: false },
          { answerText: t("G2question2opt2"), isCorrect: false },
          { answerText: t("G2question2opt3"), isCorrect: true },
        ],
      },
      {
        gamePic: null,
        triviaPic: "vpr3trivia.jpg",
        triviaTxt: t("G2question3trivia"),
        question: "Navodila3...",
        questionText: t("G2question3txt"),
        winTxt: null,
        failtxt: null,
        hasIntro: false,
        hasVR: true,
        VRAndroid: t("G2VR3Android"),
        VRIphone: t("G2VR3Iphone"),
        answerOptions: [
          { answerText: t("G2question3opt1"), isCorrect: true },
          { answerText: t("G2question3opt2"), isCorrect: false },
          { answerText: t("G2question3opt3"), isCorrect: false },
        ],
      },
    ],
  };

  return (
    <>
      <Wrapper className="quiz d-flex align-content-between flex-wrap minh-100 game2 bg-white">
        <Quiz
          gamesData={gamesData}
          quizData={quizData}
          quizStates={quizStates}
          setQuizStates={setQuizStates}
        />
      </Wrapper>
    </>
  );
}

export default Game2;
