import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { resetGamesReducer } from "store/gameSlice";
import { staggerItemsFade, itemFade } from "utils/animations";

// Import styles, media and components
import Wrapper from "components/Wrapper/Wrapper";
import Button from "components/Button/Button";
import ImgLogo from "assets/images/logo.png";
import imgBack from "assets/images/icons/back.svg";
import imgLogo from "assets/images/logo_escapebox.svg";

import imgCharacter from "assets/images/icons/character.svg";

const logoAnimation = {
  hidden: { opacity: 0, y: "-100vh", scale: 0 },
  show: {
    opacity: 1,

    y: "0",
    scale: 1,
    transition: {
      duration: 2,
      type: "spring",
      staggerChildren: 0.2,
      delayChildren: 1,
    },
  },
};

const centerPosition = {
  scale: 1,
  opacity: 0,
  left: "50%",
  top: "50%",
  translateX: "-50%",
  translateY: "-50%",
};

const itemA = {
  hidden: centerPosition,
  show: {
    scale: 0.55,
    opacity: 1,
    left: "20%",
    top: "-110%",
  },
};

const itemB = {
  hidden: centerPosition,
  show: {
    scale: 0.45,
    opacity: 1,
    left: "80%",
    top: "-130%",
  },
};

const itemC = {
  hidden: centerPosition,
  show: {
    scale: 0.6,
    opacity: 1,
    left: "50%",
    top: "-60%",
  },
};

const itemD = {
  hidden: centerPosition,
  show: {
    scale: 0.7,
    opacity: 1,
    left: "45%",
    top: "160%",
  },
};

const itemE = {
  hidden: centerPosition,
  show: {
    scale: 0.55,
    opacity: 1,
    left: "80%",
    top: "180%",
  },
};

const itemF = {
  hidden: centerPosition,
  show: {
    scale: 0.4,
    opacity: 1,
    left: "20%",
    top: "220%",
  },
};

function StartLogo() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const goToLang = () => {
    //dispatch(resetGamesReducer());
    setTimeout(() => {
      navigate("../language", { replace: true });
    }, 1000);
  };

  return (
    <Wrapper className="start minh-100 p-0 d-flex align-items-center justify-content-center text-center">
      <motion.div
        className="w-100 position-relative"
        variants={logoAnimation}
        initial="hidden"
        animate="show"
        onAnimationComplete={() => goToLang()}>
        <motion.img src={ImgLogo} className="w-75" alt="" />

        <motion.img
          src="https://planicar.escapebox.si/public/img/badge-gledalka.png"
          style={{ position: "absolute", zIndex: "0" }}
          variants={itemA}
        />
        <motion.img
          src="https://planicar.escapebox.si/public/img/badge-prostovoljec.png"
          style={{ position: "absolute", zIndex: "0" }}
          variants={itemB}
        />
        <motion.img
          src="https://planicar.escapebox.si/public/img/badge-novinarka.png"
          style={{ position: "absolute", zIndex: "0" }}
          variants={itemC}
        />

        <motion.img
          src="https://planicar.escapebox.si/public/img/badge-inzenir.png"
          style={{ position: "absolute", zIndex: "0" }}
          variants={itemD}
        />

        <motion.img
          src="https://planicar.escapebox.si/public/img/badge-trener.png"
          style={{ position: "absolute", zIndex: "0" }}
          variants={itemE}
        />

        <motion.img
          src="https://planicar.escapebox.si/public/img/badge-skakalec.png"
          style={{ position: "absolute", zIndex: "0" }}
          variants={itemF}
        />
      </motion.div>
    </Wrapper>
  );
}

export default StartLogo;
