import { useContext, useEffect } from "react";
import { GameContext } from "../GameContext";

import { useTranslation } from "react-i18next";

// Import styles, media and components
import Wrapper from "components/Wrapper/Wrapper";

import ImgLogo from "assets/images/logo.png";
import ImgPinIntro from "assets/images/icons/pin-intro.svg";

function GameIntro({ gamesData }) {
  const { t } = useTranslation();

  const { showScreens, setShowScreens } = useContext(GameContext);

  useEffect(() => {
    setTimeout(() => {
      setShowScreens({ ...showScreens, showIntro: false });
    }, 2000);
  }, []);

  return (
    <Wrapper className="game-intro minh-100 bg-gradient-blue-invert p-0">
      <div className="w-100">
        <img src={ImgLogo} className="mb-6 mt-6 w-35" alt="" />
      </div>

      <img src={ImgPinIntro} className="" alt="" />
      <p className="text-white fw-strong mt-3 mb-0">
        {t("hero")} #{gamesData.id.replace(/\D/g, "")}
      </p>
      <p className="lead text-white mt-1">{gamesData.pinopenedHeadline}</p>
    </Wrapper>
  );
}

export default GameIntro;
