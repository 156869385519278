import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { staggerItemsFade, itemFade } from "utils/animations";
import { RWebShare } from "react-web-share";
import { useMutation } from "react-query";
import axios from "axios";

// Import styles, media and components
import Wrapper from "components/Wrapper/Wrapper";
import Button from "components/Button/Button";

function SelfieSharePhoto({ watermarkPhoto }) {
  const [userId, setUserId] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();

  const clickHandler = () => {
    navigate("../finish", { replace: true });
  };

  const { isLoading, isError, error, mutate } = useMutation(uploadImage, {
    onSuccess: () => {
      console.log("success");
    },
    onError: () => {
      console.log("error");
    },
  });

  async function uploadImage() {
    await axios.post(
      "https://planicar.escapebox.si/api/public/image",
      {},
      {
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      }
    );
  }

  useEffect(() => {
    axios
      .post(
        "https://planicar.escapebox.si/api/public/image",
        {
          image: watermarkPhoto,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")),
          },
        }
      )
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .get("https://planicar.escapebox.si/api/public/user", {
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")),
        },
      })
      .then(function (response) {
        console.log(response);
        setUserId(response.data.id);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <Wrapper className="lang-picker">
      <p
        className="lead mb-5"
        dangerouslySetInnerHTML={{
          __html: t("selfieH2"),
        }}
      />

      <img className="w-100" src={watermarkPhoto} alt="selfie" />

      <div className="row">
        <div className="col-6">
          <a
            className="main-button w-100 btn btn-md btn-primary mt-3 mb-0"
            download="photoPlanicAR.jpg"
            href={watermarkPhoto}>
            {t("save")}
          </a>
        </div>
        <div className="col-6">
          <RWebShare
            data={{
              text: "Planica AR",
              url: `https://planicar.escapebox.si/?id=${userId}`,
              title: "Planica AR",
            }}
            onClick={() => console.log("shared successfully!")}>
            <Button
              label={t("share")}
              className="btn w-100 btn-md btn-primary mt-3 mb-0"
            />
          </RWebShare>
        </div>
      </div>

      <Button
        onClick={clickHandler}
        label={t("end")}
        className="btn btn-md btn-white px-5 mt-3"
      />
    </Wrapper>
  );
}

export default SelfieSharePhoto;
