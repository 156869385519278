import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { GameContext } from "views/Game/GameContext";
import { useTranslation } from "react-i18next";

// Import styles, media and components
import Quiz from "components/Quiz/Quiz";
import Wrapper from "components/Wrapper/Wrapper";

function Game5({ gamesData }) {
  const [quizStates, setQuizStates] = useState({
    currentQuestion: 0,
    score: [],
    disableAnswer: false,
    showIntro: false,
    showWinFail: false,
    gameLocked: false,
  });

  const { t } = useTranslation();

  const quizData = {
    step: [
      {
        gamePic: "game5-wind01.svg",
        triviaPic: "vpr1trivia.png",
        triviaTxt: t("question1trivia"),
        question: "Navodila1...",
        questionText: t("G5question1txt"),
        winTxt: null,
        failtxt: null,
        hasIntro: true,
        hasVR: false,
        answerOptions: [
          { answerText: t("G5question1opt1"), isCorrect: true },
          { answerText: t("G5question1opt2"), isCorrect: false },
        ],
      },
      {
        gamePic: "game5-wind02.svg",
        triviaPic: "vpr2trivia.jpg",
        triviaTxt: t("question2trivia"),
        question: "Navodila2...",
        questionText: t("G5question2txt"),
        winTxt: null,
        failtxt: null,
        hasIntro: true,
        hasVR: false,
        answerOptions: [
          { answerText: t("G5question2opt1"), isCorrect: false },
          { answerText: t("G5question2opt2"), isCorrect: true },
        ],
      },
      {
        gamePic: "game5-wind03.svg",
        triviaPic: "vpr3trivia.jpg",
        triviaTxt: t("question3trivia"),
        question: "Navodila3...",
        questionText: t("G5question3txt"),
        winTxt: null,
        failtxt: null,
        hasIntro: true,
        hasVR: false,
        answerOptions: [
          { answerText: t("G5question3opt1"), isCorrect: false },
          { answerText: t("G5question3opt2"), isCorrect: true },
        ],
      },
    ],
  };

  return (
    <>
      <Wrapper className="quiz game5 minh-100">
        <Quiz
          gamesData={gamesData}
          quizData={quizData}
          quizStates={quizStates}
          setQuizStates={setQuizStates}
        />
      </Wrapper>
    </>
  );
}

export default Game5;
