import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { staggerItemsFade, itemFade } from "utils/animations";

import ReactCodeInput from "react-code-input";

// Import styles, media and components
import Wrapper from "components/Wrapper/Wrapper";

import ImgLogo from "assets/images/logo.png";

function Loader() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      navigate("../map", { replace: true });
    }, 2000);
  }, []);

  return (
    <Wrapper className="loader d-flex align-items-center justify-content-center">
      <div>
        <img src={ImgLogo} className="mb-5 w-100" alt="" />
        <div className="loader-center">
          <div className="spinner-border text-primary mb-5" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default Loader;
