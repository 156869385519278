import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { AnimatePresence } from "framer-motion";
import { createElement, useContext } from "react";
import { GameContext } from "./GameContext";

// Import screens
import StatusBar from "components/StatusBar/StatusBar";
import PinOpened from "views/Game/PinOpened/PinOpened";
import GamePassword from "views/Game/GamePassword/GamePassword";
import PreGameIntro from "views/Game/PreGameIntro/PreGameIntro";
import GameIntro from "views/Game/GameIntro/GameIntro";
import GameBrief from "views/Game/GameBrief/GameBrief";
import GameBrief2 from "views/Game/GameBrief/GameBrief2";
import GameSuccess from "views/Game/GameSuccess/GameSuccess";
import GameBadge from "views/Game/GameBadge/GameBadge";
import GameOver from "views/Game/GameOver/GameOver";

import GameStart from "views/Game/Games/GameStart/GameStart";
import Game1 from "views/Game/Games/Game1/Game1";
import Game2 from "views/Game/Games/Game2/Game2";
import Game3 from "views/Game/Games/Game3/Game3";
import Game4 from "views/Game/Games/Game4/Game4";
import Game5 from "views/Game/Games/Game5/Game5";
import Game6 from "views/Game/Games/Game6/Game6";

// Import GameOver, GameWin, GameFail later

function Game() {
  const { t } = useTranslation();

  const gamesdata = {
    gamestart: {
      id: "gamestart",
      component: GameStart,
      headline: t("G0PinOpenedHeadline"),
      pinopenedLocation: t("G0PinOpenedLocation"),
      pinopenedHeadline: t("G0PinOpenedHeadline"),
      pinopenedDistance: t("G0PinOpenedDistance"),
      pinopenedDifficulty: t("G0PinOpenedDifficulty"),
      pinopenedInfo: t("G0PinOpenedInfo"),
      briefUrl: "Igra_1.png",
      pass: ["5", "6", "3"],
    },
    game1: {
      id: "game1",
      component: Game1,
      headline: t("G1PinOpenedHeadline"),
      pinopenedLocation: t("G1PinOpenedLocation"),
      pinopenedHeadline: t("G1PinOpenedHeadline"),
      pinopenedDistance: t("G1PinOpenedDistance"),
      pinopenedDifficulty: t("G1PinOpenedDifficulty"),
      pinopenedInfo: t("G1PinOpenedInfo"),
      pass: ["5", "6", "3"],
      briefIntro: t("G1BriefIntro"),
      briefMission: t("G1BriefMission"),
      briefUrl: "Igra_1.png",
      successTxt: t("G1SuccessTxt"),
      successDidYouKnow: false,
      badgeName: t("G1BadgeName"),
      badgeDesc: t("G1BadgeDesc"),
      badgeDesc2: t("G1BadgeDesc2"),
      badgeUrl: "badge-gledalka.png",
      questionTxt: t("G1GameQuestionTxt"),
      questionAnswer1: t("Prisojnik"),
      questionAnswer2: t("Razor"),
      questionAnswer3: t("Triglav"),
      GHCorrect0: t("G1HCorrect1"),
      GPCorrect0: t("G1PCorrect1"),
      GHCorrect1: t("G1HCorrect1"),
      GPCorrect1: t("G1PCorrect1"),
    },
    game2: {
      id: "game2",
      component: Game2,
      headline: t("G2PinOpenedHeadline"),
      pinopenedLocation: t("G2PinOpenedLocation"),
      pinopenedHeadline: t("G2PinOpenedHeadline"),
      pinopenedDistance: t("G2PinOpenedDistance"),
      pinopenedDifficulty: t("G2PinOpenedDifficulty"),
      pinopenedInfo: t("G2PinOpenedInfo"),
      pass: ["6", "1", "2"],
      briefIntro: t("G2BriefIntro"),
      briefMission: t("G2BriefMission"),
      briefUrl: "Igra_2.png",
      successTxt: t("G2SuccessTxt"),
      successDidYouKnow: false,
      gameoverTxt: t("G2GameOverTxt"),
      badgeName: t("G2BadgeName"),
      badgeDesc: t("G2BadgeDesc"),
      badgeDesc2: t("G2BadgeDesc2"),
      badgeUrl: "badge-prostovoljec.png",
      GHCorrect0: t("G2HCorrect0"),
      GPCorrect0: t("G2PCorrect0"),
      GHCorrect1: t("G2HCorrect1"),
      GPCorrect1: t("G2PCorrect1"),
      GHCorrect2: t("G2HCorrect2"),
      GPCorrect2: t("G2PCorrect2"),
      GHCorrect3: t("G2HCorrect3"),
      GPCorrect3: t("G2PCorrect3"),
    },
    game3: {
      id: "game3",
      component: Game3,
      headline: "Igra 3",
      pinopenedLocation: t("G3PinOpenedLocation"),
      pinopenedHeadline: t("G3PinOpenedHeadline"),
      pinopenedDistance: t("G3PinOpenedDistance"),
      pinopenedDifficulty: t("G3PinOpenedDifficulty"),
      pinopenedInfo: t("G3PinOpenedInfo"),
      pass: ["4", "6", "1"],
      briefIntro: t("G3BriefIntro"),
      briefMission: t("G3BriefMission"),
      briefUrl: "Igra_3.png",
      successTxt: t("G3SuccessTxt"),
      successDidYouKnow: true,
      gameoverTxt: t("G3GameOverTxt"),
      badgeName: t("G3BadgeName"),
      badgeDesc: t("G3BadgeDesc"),
      badgeDesc2: t("G3BadgeDesc2"),
      badgeUrl: "badge-novinarka.png",
      GHCorrect0: t("G3HCorrect0"),
      GPCorrect0: t("G3PCorrect0"),
      GHCorrect1: t("G3HCorrect1"),
      GPCorrect1: t("G3PCorrect1"),
      GHCorrect2: t("G3HCorrect2"),
      GPCorrect2: t("G3PCorrect2"),
      GHCorrect3: t("G3HCorrect3"),
      GPCorrect3: t("G3PCorrect3"),
    },
    game4: {
      id: "game4",
      component: Game4,
      headline: "Igra 4",
      pinopenedLocation: t("G4PinOpenedLocation"),
      pinopenedHeadline: t("G4PinOpenedHeadline"),
      pinopenedDistance: t("G4PinOpenedDistance"),
      pinopenedDifficulty: t("G4PinOpenedDifficulty"),
      pinopenedInfo: t("G4PinOpenedInfo"),
      pass: ["5", "2", "6"],
      briefIntro: t("G4BriefIntro"),
      briefMission: t("G4BriefMission"),
      briefUrl: "Igra_4.png",
      successTxt: t("G4SuccessTxt"),
      successDidYouKnow: false,
      gameoverTxt: t("G4GameOverTxt"),
      badgeName: t("G4BadgeName"),
      badgeDesc: t("G4BadgeDesc"),
      badgeDesc2: t("G4BadgeDesc2"),
      badgeUrl: "badge-inzenir.png",
      GHCorrect0: t("G4HCorrect0"),
      GPCorrect0: t("G4PCorrect0"),
      GHCorrect1: t("G4HCorrect1"),
      GPCorrect1: t("G4PCorrect1"),
    },
    game5: {
      id: "game5",
      component: Game5,
      headline: "Igra 5",
      pinopenedLocation: t("G5PinOpenedLocation"),
      pinopenedHeadline: t("G5PinOpenedHeadline"),
      pinopenedDistance: t("G5PinOpenedDistance"),
      pinopenedDifficulty: t("G5PinOpenedDifficulty"),
      pinopenedInfo: t("G5PinOpenedInfo"),
      pass: ["2", "5", "4"],
      briefIntro: t("G5BriefIntro"),
      briefMission: t("G5BriefMission"),
      briefUrl: "Igra_5.png",
      successTxt: t("G5SuccessTxt"),
      successDidYouKnow: false,
      gameoverTxt: t("G5GameOverTxt"),
      badgeName: t("G5BadgeName"),
      badgeDesc: t("G5BadgeDesc"),
      badgeDesc2: t("G5BadgeDesc2"),
      badgeUrl: "badge-trener.png",
      GHCorrect0: t("G5HCorrect0"),
      GPCorrect0: t("G5PCorrect0"),
      GHCorrect1: t("G5HCorrect1"),
      GPCorrect1: t("G5PCorrect1"),
      GHCorrect2: t("G5HCorrect2"),
      GPCorrect2: t("G5PCorrect2"),
      GHCorrect3: t("G5HCorrect3"),
      GPCorrect3: t("G5PCorrect3"),
    },
    game6: {
      id: "game6",
      component: Game6,
      headline: "Igra 6",
      pinopenedLocation: t("G6PinOpenedLocation"),
      pinopenedHeadline: t("G6PinOpenedHeadline"),
      pinopenedDistance: t("G6PinOpenedDistance"),
      pinopenedDifficulty: t("G6PinOpenedDifficulty"),
      pinopenedInfo: t("G6PinOpenedInfo"),
      pass: ["1", "5", "3"],
      briefIntro: t("G6BriefIntro"),
      briefMission: t("G6BriefMission"),
      briefUrl: "Igra_6.png",
      successTxt: t("G6SuccessTxt"),
      successDidYouKnow: false,
      gameoverTxt: t("G6GameOverTxt"),
      badgeName: t("G6BadgeName"),
      badgeDesc: t("G6BadgeDesc"),
      badgeDesc2: t("G6BadgeDesc2"),
      badgeUrl: "badge-skakalec.png",
      GHCorrect0: t("G6HCorrect0"),
      GPCorrect0: t("G6PCorrect0"),
    },
  };

  const { showScreens, setShowScreens } = useContext(GameContext);
  const completedGame = useSelector((state) => state.game.completedGame);
  const navigate = useNavigate();

  const { game } = useParams();
  const gameId = game.replace(/-/g, "");

  const GameScreens = () => {
    if (showScreens.showPinOpened) {
      return (
        <>
          <StatusBar key="status" />
          <PinOpened gamesData={gamesdata[gameId]} />
        </>
      );
    }

    if (showScreens.showGamePassword) {
      return (
        <>
          <GamePassword gamesData={gamesdata[gameId]} />
        </>
      );
    }

    /*     if (game === "gamestart" && showScreens.showVR) {
      return <VR gamesData={gamesdata[gameId]} />;
    } */

    if (game === "gamestart" && showScreens.showPreGameIntros) {
      return <PreGameIntro gamesData={gamesdata[gameId]} />;
    }

    if (showScreens.showIntro) {
      return <GameIntro gamesData={gamesdata[gameId]} />;
    }

    if (showScreens.showBrief) {
      return (
        <>
          <StatusBar key="status" />
          <GameBrief gamesData={gamesdata[gameId]} />
        </>
      );
    }

    if (showScreens.showBrief2) {
      return (
        <>
          <StatusBar key="status" />
          <GameBrief2 gamesData={gamesdata[gameId]} />
        </>
      );
    }

    if (showScreens.showSuccess) {
      return (
        <>
          <StatusBar key="status" />
          <GameSuccess gamesData={gamesdata[gameId]} />
        </>
      );
    }

    if (showScreens.showBadge) {
      return <GameBadge gamesData={gamesdata[gameId]} />;
    }

    if (showScreens.showGameOver) {
      return <GameOver gamesData={gamesdata[gameId]} />;
    }

    return createElement(gamesdata[gameId].component, {
      gamesData: gamesdata[gameId],
    });
  };

  return (
    <AnimatePresence exitBeforeEnter>
      <GameScreens />
    </AnimatePresence>
  );
}

export default Game;
