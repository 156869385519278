import { useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { GameContext } from "views/Game/GameContext";
import { useTranslation } from "react-i18next";

// Import styles, media and components
import Quiz from "components/Quiz/Quiz";
import Wrapper from "components/Wrapper/Wrapper";

import VR from "views/Game/VR/VR";
import SwipeUnlock from "components/SwipeUnlock/SwipeUnlock";
import Button from "components/Button/Button";
import Drags from "./Drags";
import Drop from "./Drop";

import ImgMala from "assets/images/game1-malaponca.svg";
import ImgSrednja from "assets/images/game1-srednjaponca.svg";
import ImgVisoka from "assets/images/game1-visokaponca.svg";
import imgARbg from "assets/images/AR1.png";

function Game1({ gamesData }) {
  const [quizStates, setQuizStates] = useState({
    currentQuestion: 0,
    score: [],
    disableAnswer: false,
    showIntro: false,
    showWinFail: false,
    showVR: false,
    gameLocked: true,
  });

  const { t } = useTranslation();

  const quizData = {
    step: [
      {
        gamePic: null,
        triviaPic: "vpr1trivia.png",
        triviaTxt: t("G2question1trivia"),
        question: "Navodila1...",
        questionText: gamesData.questionTxt,
        winTxt: null,
        failtxt: null,
        hasIntro: false,
        hasVR: true,
        VRAndroid: t("G1VR1Android"),
        VRIphone: t("G1VR1Iphone"),
        answerOptions: [
          {
            answerText: "Mala\nPonca",
            answerImg: ImgMala,
            isCorrect: false,
          },
          {
            answerText: "Srednja\nPonca",
            answerImg: ImgSrednja,
            isCorrect: false,
          },
          {
            answerText: "Visoka\nPonca",
            answerImg: ImgVisoka,
            isCorrect: true,
          },
        ],
      },
    ],
  };

  /*   var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  const isLockedClickHandler = () => {
    if (/android/i.test(userAgent)) {
      setShowScreens({
        ...showScreens,
        gameLocked: false,
      });

      window.open(t("G1VR1Android"), "_blank");
    } // iOS detection from: http://stackoverflow.com/a/9039885/177710
    else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setShowScreens({
        ...showScreens,
        showVR: true,
      });
    } else {
      setShowScreens({
        ...showScreens,
        gameLocked: false,
        showVR: true,
      });
    }
  }; */

  return (
    <>
      <Wrapper className="quiz d-flex align-content-between flex-wrap minh-100 game1 bg-white">
        <Quiz
          gamesData={gamesData}
          quizData={quizData}
          quizStates={quizStates}
          setQuizStates={setQuizStates}
        />
      </Wrapper>

      {/*       {showScreens.showVR ? (
        <VR img={imgARbg} link={t("G1VR1Iphone")} />
      ) : (
        <Wrapper className=" game1 d-flex align-content-between flex-wrap pt-3 minh-100">
          <div className={`game-container`}>
            <div className="hint bg-gradient-blue-light rounded-4 p-4 mb-3 text-start">
              <div className="text text-white display-8">
                {gamesData.questionTxt}
              </div>
            </div>

            <div className={`w-100 ${showScreens.gameLocked && "locked"}`}>
              <div className="row g-2">
                <div className="col-8">
                  <Drop
                    targetKey="Visoka"
                    gamesData={gamesData}
                    setShowScreens={setShowScreens}
                    showScreens={setShowScreens}
                  />
                </div>
                <div className="col-4">
                  <div className="border border-2 border-blue rounded-1 p-3 mb-2 ">
                    <Drags image={ImgMala} name="Mala Ponca" targetKey="Mala" />
                  </div>
                  <div className="border border-2 border-blue rounded-1 p-3 mb-2">
                    <Drags
                      image={ImgSrednja}
                      name="Srednja Ponca"
                      targetKey="Srednja"
                    />
                  </div>
                  <div className="border border-2 border-blue rounded-1 p-3">
                    <Drags
                      image={ImgVisoka}
                      name="Visoka Ponca"
                      targetKey="Visoka"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`w-100 ${
              showScreens.gameLocked ? "d-block" : "d-none"
            }`}>
            <SwipeUnlock getter={showScreens} setter={isLockedClickHandler} />
          </div>
        </Wrapper>
      )} */}
    </>
  );
}

export default Game1;
