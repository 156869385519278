import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Import styles, media and components
import Wrapper from "components/Wrapper/Wrapper";
import Button from "components/Button/Button";

function FinishScreen() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Wrapper className="finishscreen minh-100 bg-gradient-blue">
      <h1
        className="text-white mb-4"
        dangerouslySetInnerHTML={{
          __html: t("finishscreenH"),
        }}
      />

      <div
        dangerouslySetInnerHTML={{
          __html: t("finishscreenP"),
        }}></div>

      <Link to={`/lp`}>
        <Button
          label={t("finishgame")}
          className="btn btn-primary mt-4"></Button>
      </Link>
    </Wrapper>
  );
}

export default FinishScreen;
