import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { staggerItemsFade, itemFade } from "utils/animations";

// Import styles, media and components
import Wrapper from "components/Wrapper/Wrapper";
import Button from "components/Button/Button";

import ImgLogo from "assets/images/logo.png";
import imgBack from "assets/images/icons/back.svg";
import imgDown from "assets/images/icons/down.svg";

import imgUsp1 from "assets/images/icons/intro-usp01.svg";
import imgUsp2 from "assets/images/icons/intro-usp02.svg";
import imgUsp3 from "assets/images/icons/intro-usp03.svg";

function Landing() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Wrapper className="landing minh-100">
      <motion.div variants={staggerItemsFade} initial="hidden" animate="show">
        <img src={ImgLogo} className="mb-2" alt="" />

        <motion.p variants={itemFade} className="lead text-blue py-4">
          {t("LandingP1")} {t("LandingP2")}
        </motion.p>

        <motion.div variants={itemFade} className="instructions row g-1">
          <div className="col-4">
            <div className="circle bg-primary d-flex align-items-center justify-content-center mb-2 ">
              <img src={imgUsp1} alt="" />
            </div>

            <p className="small fw-bold">{t("LandingUSP1")}</p>
          </div>
          <div className="col-4">
            <div className="circle bg-primary d-flex align-items-center justify-content-center mb-2">
              <img src={imgUsp2} alt="" />
            </div>
            <p className="small fw-bold">{t("LandingUSP2")}</p>
          </div>
          <div className="col-4">
            <div className="circle bg-primary d-flex align-items-center justify-content-center mb-2">
              <img src={imgUsp3} alt="" />
            </div>
            <p className="small fw-bold">{t("LandingUSP3")}</p>
          </div>
        </motion.div>
        <motion.div variants={itemFade}>
          <Link to={`/password`}>
            <Button
              label={t("startgame")}
              className="btn btn-primary mt-4"></Button>
          </Link>
        </motion.div>

        <a href="#godown" className="d-none">
          <img className="button-down p-3" src={imgDown} />
        </a>
      </motion.div>

      <div id="godown" data-bs-spy="scroll" className="d-none">
        <div className="bg-primary bg-gradient-blue p-5">
          <p className="lead text-white">
            Kaj je interaktivno doživetje Junaki Planice?
          </p>
          <p className="text-white pt-3">
            Da si lahko prislužite laskavi naslov kraljev ali kraljic Planice,
            ni potrebno biti skakalec. Le premagati morate naštete izzive – in
            druge tekmovalce, jasno. Na poti do vrha pa boste spoznali svoje
            bodoče kraljestvo, vse pomembne osebe na dvoru (gledalci, sodniki,
            inženirji, …) in zgodovino vaše bogate dinastije (znane osebe).
          </p>
        </div>

        <div className="bg-planica p-5">
          <p className="lead">Kako poteka igra?</p>

          <div className="instructions row g-1">
            <div className="col-4">
              <div className="circle bg-primary d-flex align-items-center justify-content-center mb-2">
                <img
                  src={
                    "https://via.placeholder.com/20x20/D9D9D9/000000.png?text=USP1"
                  }
                  alt=""
                />
              </div>

              <p className="small fw-bold">{t("LandingUSP1")}</p>
            </div>
            <div className="col-4">
              <div className="circle bg-primary d-flex align-items-center justify-content-center mb-2">
                <img
                  src={
                    "https://via.placeholder.com/20x20/D9D9D9/000000.png?text=USP1"
                  }
                  alt=""
                />
              </div>
              <p className="small fw-bold">{t("LandingUSP2")}</p>
            </div>
            <div className="col-4">
              <div className="circle bg-primary d-flex align-items-center justify-content-center mb-2">
                <img
                  src={
                    "https://via.placeholder.com/20x20/D9D9D9/000000.png?text=USP1"
                  }
                  alt=""
                />
              </div>
              <p className="small fw-bold">{t("LandingUSP3")}</p>
            </div>
          </div>

          <p>
            Da si lahko prislužite laskavi naslov kraljev ali kraljic Planice,
            ni potrebno biti skakalec. Le premagati morate naštete izzive – in
            druge tekmovalce, jasno. Na poti do vrha pa boste spoznali svoje
            bodoče kraljestvo, vse pomembne osebe na dvoru (gledalci, sodniki,
            inženirji, …) in zgodovino vaše bogate dinastije (znane osebe).
          </p>
        </div>
      </div>

      <img className="button-back" onClick={() => navigate(-1)} src={imgBack} />
    </Wrapper>
  );
}

export default Landing;
