import { useContext, useEffect } from "react";
import { GameContext } from "../GameContext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import axios from "axios";

// Import styles, media and components
import Wrapper from "components/Wrapper/Wrapper";
import Button from "components/Button/Button";

import ImgLogoPlanica from "assets/images/logo_planica.svg";
import { ReactComponent as ImgStarLeft } from "assets/images/stars_left.svg";
import { ReactComponent as ImgStarRight } from "assets/images/stars_right.svg";

function GameSuccess({ gamesData }) {
  const { t } = useTranslation();

  const { showScreens, setShowScreens } = useContext(GameContext);

  const gameId = gamesData.id.replace(/\D/g, "");

  useEffect(() => {
    // Success game - ignore game0
    if (gameId.length > 0) {
      axios
        .put(
          "https://planicar.escapebox.si/api/public/user/games",
          {
            games: {
              [gameId]: true,
            },
          },
          {
            headers: {
              Authorization:
                "Bearer " + JSON.parse(localStorage.getItem("token")),
            },
          }
        )
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, []);

  const Headings = () => {
    switch (getOccurrence(showScreens.score, true)) {
      case 0:
        return gamesData.GHCorrect0;
      case 1:
        return gamesData.GHCorrect1;
      case 2:
        return gamesData.GHCorrect2;
      case 3:
        return gamesData.GHCorrect3;
      default:
        return gamesData.GHCorrect0;
    }
  };

  const Paragraphs = () => {
    switch (getOccurrence(showScreens.score, true)) {
      case 0:
        return (
          <p dangerouslySetInnerHTML={{ __html: gamesData.GPCorrect0 }}></p>
        );
      case 1:
        return (
          <p dangerouslySetInnerHTML={{ __html: gamesData.GPCorrect1 }}></p>
        );
      case 2:
        return (
          <p dangerouslySetInnerHTML={{ __html: gamesData.GPCorrect2 }}></p>
        );
      case 3:
        return (
          <p dangerouslySetInnerHTML={{ __html: gamesData.GPCorrect3 }}></p>
        );
      default:
        return "null";
    }
  };

  return (
    <Wrapper className="game-success minh-100 bg-white">
      <div className="headline-star-container bg-gradient-blue-light rounded-4 pt-5 pb-4 mt-3 mb-5">
        <h1 className="lead heading-star text-white text-uppercase heading-star__big">
          <ImgStarLeft className="star star-left" />
          <Headings />
          <ImgStarRight className="star star-right" />
        </h1>
      </div>

      <Paragraphs />

      {gamesData.successDidYouKnow && (
        <div className="didyouknow d-flex align-items-center text-start">
          <div className="logo px-3 py-2">
            <img src={ImgLogoPlanica} />
          </div>
          <div className="content p-2">
            <h1 className="fw-bold mb-1 text-uppercase display-8">
              {t("didyouknowH")}
            </h1>
            <p className="mb-0">{t("didyouknowP")}</p>
          </div>
        </div>
      )}

      <Button
        onClick={() =>
          setShowScreens({
            ...showScreens,
            showSuccess: false,
            showBadge: true,
          })
        }
        label={t("showhero")}
        className="btn btn-primary mt-3 mb-2"
      />
      <button
        onClick={() =>
          setShowScreens({
            ...showScreens,
            showSuccess: false,
            gameLocked: true,
            showBrief: true,
          })
        }
        className="btn btn-sm btn-link te">
        {t("playagain")}
      </button>
    </Wrapper>
  );
}

function getOccurrence(array, value) {
  var count = 0;
  array.forEach((v) => v === value && count++);
  return count;
}

export default GameSuccess;
