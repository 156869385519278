import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { staggerItemsFade, itemFade } from "utils/animations";
import Camera from "react-html5-camera-photo";

// Import styles, media and components
import Wrapper from "components/Wrapper/Wrapper";
import Button from "components/Button/Button";

function SelfieTakePhoto({ step, setStep, setPhoto }) {
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  function handleTakePhoto(dataUri) {
    setPhoto(dataUri);
    setStep(2);
  }

  function handleCameraError(error) {
    console.log(error);
    setIsError(true);
  }

  return (
    <Wrapper className="selfie">
      <p
        className="lead mb-5"
        dangerouslySetInnerHTML={{
          __html: t("selfieH"),
        }}
      />

      {isError && (
        <>
          <p className="lead">Ojoj, tole je pa nerodno!</p>
          <p>
            V svojih nastavitvah telefona (Settings) morate vaši kameri
            omogočiti dostop do aplikacije. Tako boste lahko ogledali zabavne AR
            vsebine, ki smo jih ustvarili za vas. Za več informacij si oglejte
            <br />
            <a
              href="https://support.google.com/chrome/answer/2693767?hl=en&co=GENIE.Platform%3DAndroid"
              target="_blank">
              ta navodila
            </a>
            .
          </p>
        </>
      )}

      <Camera
        onTakePhoto={(dataUri) => {
          handleTakePhoto(dataUri);
        }}
        onCameraError={(error) => {
          handleCameraError(error);
        }}
        isDisplayStartCameraError={false}
      />
    </Wrapper>
  );
}

export default SelfieTakePhoto;
