import useSound from "use-sound";
import soundNavigate from "../../assets/sounds/click.mp3";

function Button({ onClick, className, label }) {
  const [playNavigate] = useSound(soundNavigate, {
    volume: 0,
  });

  const clickHandler = () => {
    playNavigate();
    if (onClick) {
      onClick();
    }
  };

  return (
    <button className={`main-button ${className}`} onClick={clickHandler}>
      {label}
    </button>
  );
}

export default Button;
