import { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { GameContext } from "views/Game/GameContext";
import { useTranslation } from "react-i18next";

// Import reducers
import { completedGameReducer } from "store/gameSlice";

// Import styles, media and components
import Quiz from "components/Quiz/Quiz";
import Wrapper from "components/Wrapper/Wrapper";

function Game3({ gamesData }) {
  const [quizStates, setQuizStates] = useState({
    currentQuestion: 0,
    score: [],
    disableAnswer: false,
    showIntro: false,
    showWinFail: false,
    gameLocked: true,
  });

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { showScreens, setShowScreens } = useContext(GameContext);

  const quizData = {
    step: [
      {
        gamePic: null,
        triviaPic: "vpr1trivia.png",
        triviaTxt: t("question1trivia"),
        question: "Navodila1...",
        questionText: t("G3question1txt"),
        winTxt: null,
        failtxt: null,
        hasIntro: false,
        hasVR: true,
        VRAndroid: t("G3VR1Android"),
        VRIphone: t("G3VR1Iphone"),
        answerOptions: [
          { answerText: t("G3question1opt1"), isCorrect: false },
          { answerText: t("G3question1opt2"), isCorrect: false },
          { answerText: t("G3question1opt3"), isCorrect: true },
          { answerText: t("G3question1opt4"), isCorrect: false },
        ],
      },
      {
        gamePic: null,
        triviaPic: "vpr2trivia.jpg",
        triviaTxt: t("question2trivia"),
        question: "Navodila2...",
        questionText: t("G3question2txt"),
        winTxt: null,
        failtxt: null,
        hasIntro: false,
        hasVR: true,
        VRAndroid: t("G3VR1Android"),
        VRIphone: t("G3VR1Iphone"),
        answerOptions: [
          { answerText: t("G3question2opt1"), isCorrect: true },
          { answerText: t("G3question2opt2"), isCorrect: false },
          { answerText: t("G3question2opt3"), isCorrect: false },
          { answerText: t("G3question2opt4"), isCorrect: false },
        ],
      },
      {
        gamePic: null,
        triviaPic: "vpr3trivia.jpg",
        triviaTxt: t("question3trivia"),
        question: "Navodila3...",
        questionText: t("G3question3txt"),
        winTxt: null,
        failtxt: null,
        hasIntro: false,
        hasVR: true,
        VRAndroid: t("G3VR1Android"),
        VRIphone: t("G3VR1Iphone"),
        answerOptions: [
          { answerText: t("G3question3opt1"), isCorrect: false },
          { answerText: t("G3question3opt2"), isCorrect: false },
          { answerText: t("G3question3opt3"), isCorrect: false },
          { answerText: t("G3question3opt4"), isCorrect: true },
        ],
      },
    ],
  };

  return (
    <Wrapper className="quiz d-flex align-content-between flex-wrap minh-100 game3">
      <Quiz
        gamesData={gamesData}
        quizData={quizData}
        quizStates={quizStates}
        setQuizStates={setQuizStates}
      />
    </Wrapper>
  );
}

export default Game3;
