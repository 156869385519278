import { useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { useMutation } from "react-query";
import axios from "axios";
import { staggerItemsFade, itemFade } from "utils/animations";
import ReactCodeInput from "react-code-input";

// Import reducers
import { isAuthedReducer } from "store/gameSlice";

// Import styles, media and components
import Wrapper from "components/Wrapper/Wrapper";
import Button from "components/Button/Button";

import ImgLogo from "assets/images/logo.png";
import imgBack from "assets/images/icons/back.svg";
import imgInfo from "assets/images/icons/info.svg";

const style = {
  inputStyle: {
    width: "62px",
    margin: "3px",
    borderRadius: "6px",
    fontSize: "40px",
    height: "100px",
    border: "0",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    textAlign: "center",
    fontWeight: "900",
    color: "#095db2",
    outline: "0px solid rgba(0, 0, 0, 0.25)",
    fontFamily: "Londrina Solid",
    overflow: "visible",
  },
  inputStyleInvalid: {
    width: "62px",
    margin: "3px",
    borderRadius: "6px",
    fontSize: "40px",
    height: "100px",
    border: "0",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    textAlign: "center",
    fontWeight: "900",
    color: "red",
    outline: "2px solid red",
    fontFamily: "Londrina Solid",
    overflow: "visible",
  },
};

function Password() {
  const [isPinCodeValid, setIsPinCodeValid] = useState(true);
  const [pinCode, setPinCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const codeRef = useRef(undefined);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const checkPinCode = () => {
    setIsLoading(true);

    /* FOR PRODUCTION */
    axios
      .put(
        "https://planicar.escapebox.si/api/public/user/activate",
        {},
        {
          headers: {
            Authorization: "Bearer " + pinCode,
          },
        }
      )
      .then(function (response) {
        console.log(response);
        setIsLoading(false);
        localStorage.setItem("token", JSON.stringify(pinCode));
        navigate("../loader", { replace: true });
      })
      .catch(function (error) {
        clear();
        setPinCode("");
        setIsLoading(false);
        setIsPinCodeValid(false);
        setIsError(true);
        console.log(error);
      });
  };

  const handlePinChange = (pinCode) => {
    setIsPinCodeValid(true);
    setIsError(false);
    setPinCode(pinCode);
  };

  function clear() {
    if (codeRef.current.textInput[0]) codeRef.current.textInput[0].focus();
    codeRef.current.state.input[0] = "";
    codeRef.current.state.input[1] = "";
    codeRef.current.state.input[2] = "";
    codeRef.current.state.input[3] = "";
  }

  return (
    <Wrapper className="password minh-100">
      <motion.div variants={staggerItemsFade} initial="hidden" animate="show">
        <img src={ImgLogo} className="mb-5 w-35" alt="" />
        <motion.div variants={itemFade}>
          <p className="lead">{t("PassH1")}</p>
          <p>{t("PassP1")}</p>
          <p>{t("PassP2")}</p>
        </motion.div>

        <motion.div className="w-100" variants={itemFade}>
          <ReactCodeInput
            id="pinCode"
            type="number"
            isValid={isPinCodeValid}
            fields={4}
            onChange={handlePinChange}
            value={pinCode}
            ref={codeRef}
            {...style}
          />
          <Button
            onClick={checkPinCode}
            label="Start"
            className="btn btn-primary mt-4"
          />

          {isLoading && <h2>{t("Passcheck")}</h2>}

          {isError && (
            <h2 className="text-danger fw-bolder">{t("Passwrong")}</h2>
          )}
        </motion.div>

        <img
          className="button-back"
          onClick={() => navigate(-1)}
          src={imgBack}
        />
        <img
          className="button-info d-none"
          onClick={() => navigate(-1)}
          src={imgInfo}
        />
      </motion.div>
    </Wrapper>
  );
}

export default Password;
