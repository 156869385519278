export const dropIn = {
  hidden: {
    x: "-10vh",
    opacity: 0,
  },
  visible: {
    x: "0",

    opacity: 1,
    transition: {
      delay: 0.2,
      duration: 0.6,
      type: "spring",
      bounce: 0.3,
    },
    transitionEnd: {
      // temp workaround to fix trailing opacity and transform
      opacity: 1,
      x: 0,
    },
  },
  exit: {
    x: "30vh",
    opacity: 0,
    transition: {
      duration: 0.5,
      type: "spring",
      bounce: 0.3,
    },
  },
};

export const fadeInOut = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 1,
    },
  },
  exit: {
    opacity: 0,
  },
};

export const blurInOut = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 1,
    },
  },
  exit: {
    opacity: 0,
  },
};

export const staggerItems = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,

    transition: {
      when: "beforeChildren",
      staggerChildren: 0.3,
    },
  },
};

export const item = {
  hidden: { opacity: 0, y: "-20px", scale: 0.9 },
  show: { opacity: 1, y: "0", scale: 1 },
};

export const staggerItemsFade = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,

    transition: {
      delay: 0.1,
      when: "beforeChildren",
      staggerChildren: 0.2,
    },
  },
};

export const itemFade = {
  hidden: { opacity: 0, y: "40px" },
  show: {
    opacity: 1,
    y: "0",
    transition: { type: "spring", bounce: 0 },
  },
};

export const staggeritemFadeScale = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,

    transition: {
      delay: 0.1,
      when: "beforeChildren",
      staggerChildren: 0.3,
      staggerDirection: -1,
    },
  },
};

export const itemFadeScale = {
  hidden: { opacity: 0, y: "0", scale: 0 },
  show: {
    opacity: 1,

    y: "0",
    scale: [1, 3, 1],
    transition: { type: "spring", mass: 0.5 },
  },
};
