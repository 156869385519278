import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

import { staggerItemsFade, itemFade } from "utils/animations";

// Import reducers
import { completedGameReducer } from "store/gameSlice";

// Import styles, media and components
import Wrapper from "components/Wrapper/Wrapper";
import Button from "components/Button/Button";
import SwipeUnlock from "components/SwipeUnlock/SwipeUnlock";

import ImgLogo from "assets/images/logo.png";

function PreGameIntro4({ setStep }) {
  const [locked, setLocked] = useState(true);
  const { t } = useTranslation();

  const dragHandler = () => {
    setLocked(false);
  };

  return (
    <Wrapper className="pregame bg-gradient-blue-dark h-100">
      <motion.div variants={staggerItemsFade} initial="hidden" animate="show">
        <img src={ImgLogo} className="mb-5 w-35" alt="" />
        <motion.div className="mb-4" variants={itemFade}>
          <p className="lead">{t("G0Intro4H1")}</p>

          <div
            className="text-white"
            dangerouslySetInnerHTML={{ __html: t("G0Intro4P1") }}></div>
        </motion.div>

        <motion.div
          variants={itemFade}
          className={`${locked ? "locked" : "unlocked"}`}>
          <SwipeUnlock setter={dragHandler} />
          <Button
            onClick={() => setStep(4)}
            label={t("next")}
            className={`btn btn-primary mt-5 `}
          />
        </motion.div>
      </motion.div>
    </Wrapper>
  );
}

export default PreGameIntro4;
