import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { resetGamesReducer } from "store/gameSlice";
import { staggerItemsFade, itemFade, fadeInOut } from "utils/animations";

// Import styles, media and components
import Wrapper from "components/Wrapper/Wrapper";
import Button from "components/Button/Button";
import ImgLogo from "assets/images/logo.png";
import imgBack from "assets/images/icons/back.svg";
import imgLogo from "assets/images/logo_escapebox.svg";

import imgCharacter from "assets/images/icons/character.svg";

function Reset() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const reset = () => {
    dispatch(resetGamesReducer());
    window.location.href = "/";
  };

  return (
    <Wrapper className="Reset minh-100 p-0 d-flex align-items-center justify-content-center text-center">
      <Button className="btn btn-white" onClick={reset} label="Ponstavi igro" />
    </Wrapper>
  );
}

export default Reset;
