import { useContext, useState, useEffect, useRef } from "react";
import { emojisplosion } from "emojisplosion";
import { GameContext } from "views/Game/GameContext";
import SvgStars from "./SvgSwipeUnlockStars";

function SwipeUnlock({ getter, setter }) {
  const [maxValue, setMaxValue] = useState(150);
  const [speed, setSpeed] = useState(12);
  const [currValue, setCurrValue] = useState(0);

  const inputRange = useRef(null);

  const { showScreens, setShowScreens } = useContext(GameContext);

  const unlockStartHandler = (e) => {
    setCurrValue(+e.target.value);
  };

  const unlockEndHandler = (e) => {
    // store current value
    setCurrValue(+e.target.value);
    // determine if we have reached success or not
    if (e.target.value >= maxValue) {
      successHandler();
    } else {
      setCurrValue(0);
      //inputRange.current.value = 0;
      window.requestAnimationFrame(animateHandler);
    }
  };

  const animateHandler = () => {
    if (inputRange.current !== null) {
      inputRange.current.value = currValue;
    }

    setCurrValue(currValue - speed);
  };

  const successHandler = () => {
    inputRange.current.value = 0;
    setCurrValue(0);
    //window.requestAnimationFrame(animateHandler);

    setter();
  };

  const onChange = (e) => {
    const range = document.querySelector("input[type=range]");
    const ratio = ((range.value - range.min) / (range.max - range.min)) * 100;

    emojisplosion({
      emojiCount: 1,
      emojis: ["🌟", "⭐", "✨"],

      physics: {
        gravity: 0.5,
        preserveOutOfBounds: false,
      },

      position() {
        // https://stackoverflow.com/questions/1480133
        const offset = cumulativeOffset(range);

        return {
          x: 80 + ratio * 1.8,
          y: offset.top + range.clientHeight / 2,
        };
      },
    });
  };

  useEffect(() => {
    if (!showScreens.showVR) {
      inputRange.current.addEventListener(
        "mousedown",
        unlockStartHandler,
        false
      );
      inputRange.current.addEventListener(
        "mousestart",
        unlockStartHandler,
        false
      );
      inputRange.current.addEventListener("mouseup", unlockEndHandler, false);
      inputRange.current.addEventListener("touchend", unlockEndHandler, false);
    }
  }, []);

  return (
    <>
      <div className={`swipe-unlock d-flex align-items-center`}>
        <input
          type="range"
          defaultValue={0}
          min={0}
          max={150}
          className="pullee"
          onChange={onChange}
          ref={inputRange}
        />
        <SvgStars />
      </div>
    </>
  );
}

var cumulativeOffset = function (element) {
  var top = 0,
    left = 0;
  do {
    top += element.offsetTop || 0;
    left += element.offsetLeft || 0;
    element = element.offsetParent;
  } while (element);

  return {
    top: top,
    left: left,
  };
};

export default SwipeUnlock;
