import { useContext } from "react";

import { useTranslation } from "react-i18next";

import { GameContext } from "../GameContext";

// Import styles, media and components

import Button from "components/Button/Button";
import Wrapper from "components/Wrapper/Wrapper";

import imgAR from "assets/images/bg_planica.jpg";

function VR({ gamesData, img, link, setQuizStates, quizStates }) {
  const { t } = useTranslation();

  const { showScreens, setShowScreens } = useContext(GameContext);
  console.log(quizStates, "QUIZSTATES");
  return (
    <Wrapper className="ar d-flex align-content-between flex-wrap p-0 minh-100">
      <a
        onClick={
          quizStates !== undefined
            ? () =>
                setQuizStates({
                  ...quizStates,
                  showVR: false,
                  gameLocked: false,
                })
            : () =>
                setShowScreens({
                  ...showScreens,
                  showVR: false,
                  gameLocked: false,
                })
        }
        href={link}
        rel="ar">
        <img style={{ height: "100vh", filter: "blur(5px)" }} src={imgAR} />
        <Button
          label={t("openvr")}
          className={`btn btn-center btn-primary mt-3 `}
          disabled
        />
      </a>
    </Wrapper>
  );
}

export default VR;
