import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { staggerItemsFade, itemFade } from "utils/animations";

// Import styles, media and components
import Wrapper from "components/Wrapper/Wrapper";
import Button from "components/Button/Button";

function Heroes3({ step, setStep }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const clickHandler = () => {
    navigate("../selfie", { replace: true });
  };

  return (
    <Wrapper className="lang-picker">
      <h1>Heroes3</h1>

      <Button
        onClick={clickHandler}
        label={t("next")}
        className="btn btn-sm btn-primary"
      />
    </Wrapper>
  );
}

export default Heroes3;
